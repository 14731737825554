import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-forgot-password',
  templateUrl: './user-forgot-password.component.html',
  standalone: true,
  styleUrls: ['./user-forgot-password.component.scss']
})
export class UserForgotPasswordComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
