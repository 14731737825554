import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable} from "rxjs";
import {LoaderService} from "../navigation/loader/loader.service";
import {tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private loaderService: LoaderService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loaderService.addRequestLoading();
    return next.handle(request).pipe(
      tap(
        () => {},
        () => this.loaderService.removeRequestLoading(),
        () => this.loaderService.removeRequestLoading()
      )
    );
  }
}

