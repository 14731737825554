import {MapElement, Projection} from "../map-element/map-element";
import {Set} from "../set/set";
import {RenovationStatus} from "../renovation/renovation";

export interface Skin {
  id: string
  uniqueId: string|null
  name: string
  mapElements: MapElement[]
  set: Set | null
  type: SkinType
  rareLevel: RareLevel
  softCurrency: SoftCurrency | null
  price: number | null
  expForAccessibility: number | null
  quest: string | null
  isRoyal: boolean
  status: RenovationStatus;
  skinRenovationId: number
  createdAt: Date
  updatedAt: Date|null
}

export interface SkinDto {
  uniqueId: string
  name: string
  projection: Projection
  setUniqueId: string | null
  rareLevel: RareLevel
  price: number | null
}

export interface ImportSkin extends SkinDto {
  isGift: boolean
  isEventSkin: boolean
  currency: string
}

export interface ExportSkin extends SkinDto {
  skinType: SkinType
  rareLevel: RareLevel
  currency: SoftCurrency | null
  expForAccessibility: number | null
  isRoyal: boolean
}

export enum SkinType {
  NONE = -1,
  BASE = 0,
  COMMON = 1,
  EVENT_OR_TLE = 2,
  AWARDS = 3,
  EVENT_AWARD = 4,
}

export enum SoftCurrency {
  CURRENCY_RUBY = 2,
  CURRENCY_COIN = 4,
}

export enum RareLevel {
  ZERO = 0,
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
}

// TODO Додати в параметри User хто додав та підтвердив скін
