// import {FieldMap} from "angular2-query-builder";

export interface Deploy {
  id: number
  name: string
  description: string
  applyText: string
  hyperLink: string|null
  templateId: number
  state: DeployState
  createdAt: Date
  updatedAt: Date|null
  activeTimeFrom: Date|null
  activeTimeTo: Date|null
  isActiveTimeNow: boolean
  userId: number
  username: string
  redeems: Redeem[]
  players: string[]|null
  appliedPlayersCount: number
  appliedPlayers: string[]
  testPlayers: TestPlayer[]|null
  // query: FieldMap
  query: null
}

export interface TestPlayer {
  id: number,
  playerId: string,
  isApplied: boolean
}

export interface Redeem {
  id: string
  name: string|null
  type: number
  value: string // [100, +100, -100, =100, =+100, =-100]
}

export enum DeployState {
  initial = "initial",
  applied = "applied",
  reverted = "reverted",
  declined = "declined",
  deleted = "deleted"
}

export interface RuleValidatorResult {
  isValidOperator: boolean;
  isValidValue: boolean;
}
