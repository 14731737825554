import { Injectable } from '@angular/core';
// import {NgxPermissionsService} from "ngx-permissions";

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  // constructor(private _ngxPermissionsService: NgxPermissionsService) {}
  //
  // get ngxPermissionsService(): NgxPermissionsService {
  //   return this._ngxPermissionsService;
  // }

  loadPermissions(): void {
    const permissions = localStorage.getItem('permissions');
    // this._ngxPermissionsService.loadPermissions(JSON.parse(permissions ? permissions : '[]'));
  }

  setUserPermissions(permissions: string[]): void {
    localStorage.setItem('permissions', JSON.stringify(permissions));
  }

  removePermissions() {
    localStorage.removeItem('permissions');
  }
}
