import { Component, OnInit } from '@angular/core';
import {RemoteConfigListService} from "./remote-config-list.service";
import {GroupedRemoteConfigs, RemoteConfig} from "../remote-config/remote-config";
import {Observable} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";
import {NotificationsService} from "angular2-notifications";
import {NgForOf, NgIf, NgTemplateOutlet} from '@angular/common';
import {NgxJsonViewerComponent} from '../../components/ngx-json-viewer/ngx-json-viewer.component';
import {FormsModule} from '@angular/forms';
import {InternalNotificationService} from '../../functions/notification';
import {DeployState} from '../deploy/deploy';

@Component({
  selector: 'app-remote-config-list',
  templateUrl: './remote-config-list.component.html',
  standalone: true,
  imports: [
    NgTemplateOutlet,
    NgForOf,
    NgIf,
    NgxJsonViewerComponent,
    FormsModule
  ],
  styleUrls: ['./remote-config-list.component.scss']
})
export class RemoteConfigListComponent implements OnInit {

  remoteConfigs$: Observable<RemoteConfig[]>;
  remoteConfigsGroup: GroupedRemoteConfigs = {}
  isEditedContentList: boolean[] = [];
  currentOriginalEditedContent: string|null = null;
  visibleRemoteConfigGroups: string[] = [];

  parseContentStringToJson(content: string) {
    try {
      return JSON.parse(content);
    } catch (e) {
      console.warn("Invalid remote config json");
      return {
        "error": "Invalid json"
      };
    }
  }

  isValidJson(content: string): boolean {
    try {
      JSON.parse(content);
      return true;
    } catch (e) {
      return false;
    }
  }

  constructor(
    public remoteConfigListService: RemoteConfigListService,
    private internalNotificationService: InternalNotificationService,
  ) {
    this.remoteConfigs$ = this.remoteConfigListService.remoteConfigs$;
  }

  ngOnInit(): void {
    this.fetchRemoteConfigList();
  }

  fetchRemoteConfigList() {
    this.remoteConfigListService.getRemoteConfigList().subscribe((data) => {
      if (data.body !== null) {
        this.remoteConfigListService.setRemoteConfigs(data.body)
        this.remoteConfigsGroup = {}

        for (const rc of data.body as RemoteConfig[]) {
          this.isEditedContentList[rc.id] = false;

          if (!this.remoteConfigsGroup[rc.name]) {
            this.remoteConfigsGroup[rc.name] = [rc];
          } else {
            this.remoteConfigsGroup[rc.name].push(rc);
          }
        }

        const rangeHeader = data.headers.get('Content-Range');

        if (rangeHeader !== null) {
          this.remoteConfigListService.parseRangeHeader(rangeHeader)
        }
      } else {
        this.remoteConfigListService.setRemoteConfigs([])
      }
    });
  }

  changeOrderBy(columnName: string) {
    this.remoteConfigListService.changeOrderBy(columnName);
    this.fetchRemoteConfigList()
  }

  changePage(page: number) {
    this.remoteConfigListService.changePage(page);
    this.fetchRemoteConfigList()
  }

  editContentAndCloseOthers(remoteConfigId: number, originalContent: string): void {
    for (let i = 0; i < this.isEditedContentList.length; i++) {
      if (i === remoteConfigId) {
        this.isEditedContentList[remoteConfigId] = !this.isEditedContentList[remoteConfigId];

        if (this.isEditedContentList[remoteConfigId]) {
          this.currentOriginalEditedContent = originalContent;
        } else {
          this.currentOriginalEditedContent = null;
        }

      } else {
        this.isEditedContentList[i] = false;
      }
    }
  }

  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  showRemoteConfigGroup(remoteConfigName: string) {
    if (this.visibleRemoteConfigGroups.includes(remoteConfigName)) {
      this.visibleRemoteConfigGroups = this.visibleRemoteConfigGroups.filter(function(name){
        return name !== remoteConfigName;
      });

      return;
    }

    this.visibleRemoteConfigGroups.push(remoteConfigName);
  }

  isContentWasChangedAndValid(remoteConfig: RemoteConfig) {
    // console.log(this.currentOriginalEditedContent === remoteConfig.content);
    // console.log(this.currentOriginalEditedContent !== null, this.currentOriginalEditedContent !== remoteConfig.content, this.isValidJson(remoteConfig.content));
    return (this.currentOriginalEditedContent !== null
      && this.currentOriginalEditedContent !== remoteConfig.content
      && this.isValidJson(remoteConfig.content));
  }

  updateRemoteConfig(remoteConfig: RemoteConfig) {
    if (!confirm('Ви впевнені що хочете змінити конфіг?')) {
      return;
    }

    this.remoteConfigListService.updateRemoteConfigContent(remoteConfig.id, remoteConfig.content).subscribe({
      next: (data) => {
        if (data.status === 200) {
          this.internalNotificationService.showSuccess('Успіх', 'Ремоут конфіг змінено')
          this.fetchRemoteConfigList();
        }
      },
      error: (error) => {
        this.internalNotificationService.handleError(error, 'Невдалося оновити ремоут конфіг');
      }
    })
  }

  deleteRemoteConfig(remoteConfig: RemoteConfig) {
    if (!confirm('Ви впевнені що хочете видалити конфіг #' + remoteConfig.id + '?')) {
      return;
    }

    this.remoteConfigListService.deleteRemoteConfig(remoteConfig.id).subscribe({
      next: (data) => {
        if (data.status === 200) {
          this.internalNotificationService.showSuccess('Успіх', 'Ремоут конфіг видалено')
          this.fetchRemoteConfigList();
        }
      },
      error: (error) => {
        this.internalNotificationService.handleError(error, 'Невдалося видалити ремоут конфіг');
      }
    })
  }

  downloadRemoteConfigConfigAsJsonFile(name: string, content: string) {
    const blob = new Blob([content], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = name + '.json';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
}
