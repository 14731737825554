import { Injectable } from '@angular/core';
import {Subject} from "rxjs";
import {PageTypeEnum} from "./common/page";

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private _pageType$ = new Subject<PageTypeEnum>();
  public isFullLeftSideMenu: boolean = false;

  constructor() { }

  get pageType$(): Subject<PageTypeEnum> {
    return this._pageType$;
  }

  setPageType$(value: PageTypeEnum) {
    this._pageType$.next(value);
  }

  get env(): string {
    if (window.location.href.includes("localhost")) {
      return 'dev'
    } else if (window.location.href.includes("back-test")) {
      return 'test'
    } else if (window.location.href.includes("back-rc")) {
      return 'rc'
    } else {
      return 'prod'
    }
  }
}
