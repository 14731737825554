import { Component, OnInit } from '@angular/core';
import {Observable} from "rxjs";
import {Dashboard} from "./dashboard";
import {DashboardService} from "./dashboard.service";
import {HttpClient} from "@angular/common/http";
import {InternalNotificationService} from '../functions/notification';
import {AsyncPipe, NgIf} from '@angular/common';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe
  ],
  providers: [
    HttpClient,
  ],
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  dashboard$: Observable<Dashboard>;

  constructor(
    private dashboardService: DashboardService,
    private internalNotificationService: InternalNotificationService,
  ) {
    this.dashboard$ = this.dashboardService.dashboard$;
  }

  ngOnInit(): void {
    this.fetchDashboardData()
  }

  fetchDashboardData(): void {
    this.dashboardService.getDashboardData().subscribe({
      next: (data) => {
        if (data.status === 200) {
          this.dashboardService.setDashboard(data.body);
        }
      },
      error: (error) => {
        this.internalNotificationService.handleError(error, "Невдалося отримати загальні дані");
      }
    })
  }
}
