import { Component, OnInit } from '@angular/core';
import {ApplicationLogsListService} from "./application-logs-list.service";
import {Observable} from "rxjs";
import {RouterLink, RouterLinkActive} from '@angular/router';
import {AsyncPipe, NgForOf, NgIf} from '@angular/common';

@Component({
  selector: 'app-application-logs-list',
  templateUrl: './application-logs-list.component.html',
  standalone: true,
  imports: [
    RouterLink,
    NgForOf,
    RouterLinkActive,
    NgIf,
    AsyncPipe
  ],
  styleUrls: ['./application-logs-list.component.scss']
})
export class ApplicationLogsListComponent implements OnInit {
  list$: Observable<string[]>;

  constructor(private applicationLogsListService: ApplicationLogsListService) {
    this.list$ = this.applicationLogsListService.list$;
  }

  ngOnInit(): void {
    this.applicationLogsListService.getList().subscribe(
      (data) => {
        if (data.body !== null) {
          this.applicationLogsListService.setList(data.body);
        }
      }
    );
  }
}
