import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ApplicationLogService} from "./application-log.service";
import {Observable} from "rxjs";
import {Log} from "../log";
import {AsyncPipe, DatePipe, NgForOf, NgIf, UpperCasePipe} from '@angular/common';
import {JsonPrettyPrintPipe} from '../../pipes/json-pretty-print.pipe';

@Component({
  selector: 'app-application-log',
  templateUrl: './application-log.component.html',
  standalone: true,
  imports: [
    NgIf,
    JsonPrettyPrintPipe,
    NgForOf,
    UpperCasePipe,
    AsyncPipe,
    DatePipe
  ],
  styleUrls: ['./application-log.component.scss']
})
export class ApplicationLogComponent implements OnInit {

  logs$: Observable<Log[]>;
  isShowingLogContextModal: boolean = false;
  currentActiveLogContext: string|null = null

  constructor(
    private applicationLogService: ApplicationLogService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.logs$ = this.applicationLogService.logs$;
  }

  ngOnInit(): void {
    const filename = this.route.snapshot.paramMap.get('filename');
    const requestId = this.route.snapshot.paramMap.get('requestId');

    if (filename && requestId) {
      this.applicationLogService.getLog(filename, requestId).subscribe(
        (data) => {
          if (data.body !== null) {
            this.applicationLogService.setLog(data.body);
          }
        }
      );
    }
    // else {
    //   this.router.navigateByUrl(/)
    // }
  }

  showLogContextModal(context: string|null) {
    const parsedContext = context ? JSON.parse(context) : null;

    if (typeof parsedContext === "object"
      && parsedContext.data !== undefined
      && parsedContext.data !== null
      && parsedContext.data !== ''
    ) {
      try {
        parsedContext.data = JSON.parse(parsedContext.data);
      } catch (e) {}
    }

    this.currentActiveLogContext = parsedContext;
    this.isShowingLogContextModal = !this.isShowingLogContextModal;
  }
}
