import {inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AuthorizationService} from "../../security/authorization.service";
import {PermissionService} from "../../security/permission.service";
import {NotificationsService} from "angular2-notifications";
import {Router} from "@angular/router";
import {InternalNotificationService} from '../../functions/notification';

@Injectable({
  providedIn: 'root'
})
export class UserLogoutService {

  constructor(
    private http: HttpClient,
    private authorizationService: AuthorizationService,
    private permissionService: PermissionService,
    private internalNotificationService: InternalNotificationService,
    private router: Router
  ) {}

  logout(): void {
    this.http.post('/api/v1/security/logout', {}, {observe: 'response'}).subscribe({
      next: (data) => {
        if (data.status === 200) {
          this.authorizationService.removeId();
          this.authorizationService.removeToken();
          this.permissionService.removePermissions();
          this.authorizationService.isStillAuthenticated();
          this.internalNotificationService.showInfo('Авторизація', 'Успішний вихід');

          setTimeout(() => {
            this.router.navigateByUrl('/login');
          }, 1500);
        }
      },
      error: (error) => {
        this.internalNotificationService.handleError(error, "Невдалося вийти з аккаунта");
      }
    })
  }
}
