<div class="flex flex-col">
<!--    <div class="pb-4">-->
<!--        <p class="mt-2 max-w-4xl text-sm text-gray-500 ml-4">Список последних игровых сессий</p>-->
<!--    </div>-->
    <div class="bg-gray-50 {{loaderService.isActiveLoader() && saveListService.total === 0 ? 'mb-11' : ''}} overflow-x-auto overflow-hidden border border-gray-200 rounded-lg з-">
        <div class="px-4 py-4 {{isAdvancedSearchEnabled ? 'border-b border-gray-200' : ''}}">
            <div class="flex flex-row">
                <div class="flex flex-row">
                    <button type="button"
                            style="height: 38px"
                            class=" w-42 inline-flex items-center px-3.5 py-2 border border-blue-200 hover:border-blue-400 text-sm leading-4 rounded-md bg-white text-blue-500 hover:text-blue-700 focus:outline-none"
                            (click)="enableCheatSavesList()">
                      {{isCheatSavesListEnabled ? 'Сейви гравців' : 'Чит сейви' }}
                    </button>
<!--                    <button *ngIf="!isCheatSavesListEnabled" type="button"-->
<!--                            style="height: 38px"-->
<!--                            class="w-42 inline-flex items-center px-3.5 py-2 border border-blue-200 hover:border-blue-400 text-sm leading-4 rounded-md bg-white text-blue-500 hover:text-blue-700 focus:outline-none"-->
<!--                            (click)="enableAdvancedSearch()">-->
<!--                        Конструктор запитів-->
<!--                    </button>-->
                    <form *ngIf="!isAdvancedSearchEnabled && !isCheatSavesListEnabled" [formGroup]="searchSavesByPlayerIdsCsvFileForm" (change)="onChangePlayerIdsCsvFileForm()">
                      <app-file-upload formControlName="playerIdsCsvFile" [isValid]="isValidOrNullPlayerIdsCsvFile()"></app-file-upload>
                    </form>
                    <form *ngIf="!isAdvancedSearchEnabled && !isCheatSavesListEnabled" [formGroup]="searchSaveByPlayerIdForm" (change)="onChangePlayerIdForm()" class="ml-4 min-w-0 md:px-8 lg:px-0 xl:col-span-6">
                        <div class="flex items-center px-6 md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0">
                            <div class="w-full">
                                <label for="playerId" class="sr-only">Search player</label>
                                <div class="relative">
                                    <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                                        <!-- Heroicon name: solid/search -->
                                        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                                        </svg>
                                    </div>
                                    <input formControlName="playerId"
                                           id="playerId"
                                           name="search"
                                           class="block border border-gray-200 rounded-md w-80 py-2 pl-10 pr-3 text-sm text-gray-500 focus:text-gray-500 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 sm:text-sm"
                                           placeholder="Шукати гравця"
                                           type="search">
                                </div>
                            </div>
                        </div>
                    </form>
                    <form *ngIf="isCheatSavesListEnabled" [formGroup]="searchCheatSaveByNameForm" (change)="onChangeSaveNameForm()" class="min-w-0 md:px-8 lg:px-0 xl:col-span-6">
                      <div class="flex items-center px-6 md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0">
                        <div class="w-full">
                          <label for="playerId" class="sr-only">Search player</label>
                          <div class="relative ml-4 ">
                            <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                              <!-- Heroicon name: solid/search -->
                              <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                              </svg>
                            </div>
                            <input formControlName="cheatSaveName"
                                   id="cheatSaveName"
                                   name="search"
                                   class="block border border-gray-200 rounded-md w-80 py-2 pl-10 pr-3 text-sm text-gray-500 focus:text-gray-500 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 sm:text-sm"
                                   placeholder="Шукати чит сейв"
                                   type="search">
                          </div>
                        </div>
                      </div>
                    </form>
                </div>
              <span *ngIf="!isCheatSavesListEnabled" class="py-2 px-4 text-sm text-gray-500">Вибрано: {{saveListService.isAllPlayersSelected ? saveListService.saveListTotalCount : getSelectedPlayersCount()}} / {{saveListService.saveListTotalCount}}</span>
              <button *ngIf="canBeDeployed()" (click)="deploy()" class="ml-4 w-24 py-2 px-4 bg-green-300 text-sm text-white font-semibold rounded-lg hover:bg-green-500">Деплой</button>
              <button *ngIf="canMovePlayerSaveToCheatSave()" (click)="movePlayerSaveToCheatSave()" class="ml-4 w-28 py-2 px-4 bg-green-300 text-sm text-white font-semibold rounded-lg hover:bg-green-500">В чит сейв</button>
              <button *ngIf="canMovePlayerSaveToCheatSave()" (click)="moveCheatSaveToPlayerSave()" class="ml-4 w-32 py-2 px-4 bg-green-300 text-sm text-white font-semibold rounded-lg hover:bg-green-500">З чит сейву</button>
            </div>
        </div>
<!--        <app-query-builder *ngIf="isAdvancedSearchEnabled"></app-query-builder>-->
    </div>
    <div class="bg-gray-100">
        <nav class="px-4 flex items-center justify-between sm:px-0">
            <div class="-mt-px w-0 flex-1 flex">
                <a [routerLink]="" queryParamsHandling="preserve" (click)="changePage(saveListService.currentPage - 1)" *ngIf="saveListService.currentPage > 1" class="cursor-pointer border-t-2 border-transparent py-3 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                    <!-- Heroicon name: solid/arrow-narrow-left -->
                    <svg class="mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                    </svg>
                    Попередня сторінка
                </a>
            </div>
            <div *ngFor="let page of saveListService.paginationList" class="hidden md:-mt-px md:flex">
                <a *ngIf="page !== '...'" [routerLink]="" queryParamsHandling="preserve" (click)="changePage(page)" class="{{page === saveListService.currentPage ? 'border-blue-500 text-blue-600' : 'cursor-pointer border-transparent hover:border-gray-300 hover:text-gray-700'}} text-gray-500 border-t-2 py-3 px-4 inline-flex items-center text-sm font-medium">
                    {{page}}
                </a>
                <span *ngIf="page === '...'" class="border-transparent text-gray-500 border-t-2 py-3 px-4 inline-flex items-center text-sm font-medium">
                  {{page}}
                </span>
            </div>
            <div class="-mt-px w-0 flex-1 flex justify-end">
                <a [routerLink]="" queryParamsHandling="preserve" (click)="changePage(saveListService.currentPage + 1)" *ngIf="saveListService.totalPage > 1 && saveListService.currentPage !== saveListService.totalPage" class="cursor-pointer border-t-2 border-transparent py-3 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                    Наступна сторінка
                    <!-- Heroicon name: solid/arrow-narrow-right -->
                    <svg class="ml-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                </a>
            </div>
        </nav>
    </div>
    <div class="bg-gray-50 overflow-x-auto overflow-hidden border border-gray-200 rounded-lg з-">
        <table appSelectRows style="width: max-content;" class="table-fixed divide-y divide-gray-200">
            <thead class="bg-gray-50">
                <tr>
                    <th *ngIf="!isCheatSavesListEnabled" scope="col" class="relative w-12 pl-6">
                        <div class="flex flex-row" (change)="selectAllPlayers()">
                            <input type="checkbox" [checked]="isAllPlayersSelected() || saveListService.selectedPages.includes(this.saveListService.currentPage)" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-400 focus:ring-blue-400 focus:text-blue-400 sm:left-6">
                        </div>
                    </th>
                    <th (click)="changeOrderBy('id')" scope="col" class="pl-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <ng-container *ngIf="saveListService.orderByColumn === 'id'"
                                      [ngTemplateOutlet]="saveListService.orderDirection == 'DESC' ? orderDirectionDesc : orderDirectionAsc"
                                      [ngTemplateOutletContext]="{columnName:'Save ID'}">
                        </ng-container>
                        {{saveListService.orderByColumn !== 'id' ? "Save ID" : ""}}
                    </th>
                    <th *ngIf="isCheatSavesListEnabled" (click)="changeOrderBy('saveName')" scope="col" class="pl-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <ng-container *ngIf="saveListService.orderByColumn === 'saveName'"
                                    [ngTemplateOutlet]="saveListService.orderDirection == 'DESC' ? orderDirectionDesc : orderDirectionAsc"
                                    [ngTemplateOutletContext]="{columnName:'Save Name'}">
                      </ng-container>
                      {{saveListService.orderByColumn !== 'saveName' ? "Save Name" : ""}}
                    </th>
                    <th *ngIf="!isCheatSavesListEnabled" scope="col" class="pl-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Player ID
                    </th>
                    <th *ngIf="!isCheatSavesListEnabled" scope="col" class="pl-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Device ID
                    </th>
                    <th *ngIf="!isCheatSavesListEnabled" scope="col" class="pl-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Game Session ID
                    </th>
                    <th *ngIf="!isCheatSavesListEnabled" (click)="changeOrderBy('receivedTime')" scope="col" class="pl-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <ng-container *ngIf="saveListService.orderByColumn === 'receivedTime'"
                                      [ngTemplateOutlet]="saveListService.orderDirection == 'DESC' ? orderDirectionDesc : orderDirectionAsc"
                                      [ngTemplateOutletContext]="{columnName:'Received Time'}">
                        </ng-container>
                        {{saveListService.orderByColumn !== 'receivedTime' ? "Received Time" : ""}}
                    </th>
                    <th *ngIf="!isCheatSavesListEnabled" (click)="changeOrderBy('level')" scope="col" class="pl-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <ng-container *ngIf="saveListService.orderByColumn === 'level'"
                                      [ngTemplateOutlet]="saveListService.orderDirection == 'DESC' ? orderDirectionDesc : orderDirectionAsc"
                                      [ngTemplateOutletContext]="{columnName:'Level'}">
                        </ng-container>
                        {{saveListService.orderByColumn !== 'level' ? "Level" : ""}}
                    </th>
                    <th *ngIf="!isCheatSavesListEnabled" (click)="changeOrderBy('coins')" scope="col" class="pl-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <ng-container *ngIf="saveListService.orderByColumn === 'coins'"
                                      [ngTemplateOutlet]="saveListService.orderDirection == 'DESC' ? orderDirectionDesc : orderDirectionAsc"
                                      [ngTemplateOutletContext]="{columnName:'Coins'}">
                        </ng-container>
                        {{saveListService.orderByColumn !== 'coins' ? "Coins" : ""}}
                    </th>
                    <th *ngIf="!isCheatSavesListEnabled" (click)="changeOrderBy('ruby')" scope="col" class="pl-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <ng-container *ngIf="saveListService.orderByColumn === 'ruby'"
                                      [ngTemplateOutlet]="saveListService.orderDirection == 'DESC' ? orderDirectionDesc : orderDirectionAsc"
                                      [ngTemplateOutletContext]="{columnName:'Ruby'}">
                        </ng-container>
                        {{saveListService.orderByColumn !== 'ruby' ? "Ruby" : ""}}
                    </th>
                    <th *ngIf="!isCheatSavesListEnabled" (click)="changeOrderBy('keys')" scope="col" class="pl-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <ng-container *ngIf="saveListService.orderByColumn === 'keys'"
                                      [ngTemplateOutlet]="saveListService.orderDirection == 'DESC' ? orderDirectionDesc : orderDirectionAsc"
                                      [ngTemplateOutletContext]="{columnName:'Keys'}">
                        </ng-container>
                        {{saveListService.orderByColumn !== 'keys' ? "Keys" : ""}}
                    </th>
                    <th *ngIf="!isCheatSavesListEnabled" (click)="changeOrderBy('percent')" scope="col" class="pl-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <ng-container *ngIf="saveListService.orderByColumn === 'percent'"
                                      [ngTemplateOutlet]="saveListService.orderDirection == 'DESC' ? orderDirectionDesc : orderDirectionAsc"
                                      [ngTemplateOutletContext]="{columnName:'Percent'}">
                        </ng-container>
                        {{saveListService.orderByColumn !== 'percent' ? "Percent" : ""}}
                    </th>
                    <th *ngIf="!isCheatSavesListEnabled" (click)="changeOrderBy('historyNumber')" scope="col" class="pl-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <ng-container *ngIf="saveListService.orderByColumn === 'historyNumber'"
                                      [ngTemplateOutlet]="saveListService.orderDirection == 'DESC' ? orderDirectionDesc : orderDirectionAsc"
                                      [ngTemplateOutletContext]="{columnName:'History №'}">
                        </ng-container>
                        {{saveListService.orderByColumn !== 'historyNumber' ? "History №" : ""}}
                    </th>
                    <th *ngIf="!isCheatSavesListEnabled" (click)="changeOrderBy('version')" scope="col" class="pl-2 pr-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <ng-container *ngIf="saveListService.orderByColumn === 'version'"
                                      [ngTemplateOutlet]="saveListService.orderDirection == 'DESC' ? orderDirectionDesc : orderDirectionAsc"
                                      [ngTemplateOutletContext]="{columnName:'Version'}">
                        </ng-container>
                        {{saveListService.orderByColumn !== 'version' ? "Version" : ""}}
                    </th>
                    <th (click)="changeOrderBy('appVersion')" scope="col" class="pl-2 pr-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <ng-container *ngIf="saveListService.orderByColumn === 'appVersion'"
                                    [ngTemplateOutlet]="saveListService.orderDirection == 'DESC' ? orderDirectionDesc : orderDirectionAsc"
                                    [ngTemplateOutletContext]="{columnName:'App Version'}">
                      </ng-container>
                      {{saveListService.orderByColumn !== 'appVersion' ? "App Version" : ""}}
                    </th>
                    <th *ngIf="isCheatSavesListEnabled" (click)="changeOrderBy('createdAt')" scope="col" class="pl-2 pr-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <ng-container *ngIf="saveListService.orderByColumn === 'createdAt'"
                                    [ngTemplateOutlet]="saveListService.orderDirection == 'DESC' ? orderDirectionDesc : orderDirectionAsc"
                                    [ngTemplateOutletContext]="{columnName:'Created At'}">
                      </ng-container>
                      {{saveListService.orderByColumn !== 'createdAt' ? "Created At" : ""}}
                    </th>
                    <th *ngIf="isCheatSavesListEnabled" scope="col" class="pl-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Updated At
                    </th>
                </tr>
            </thead>
            <tbody class="{{saveListService.isAllPlayersSelected ? 'bg-gray-50' : 'bg-white'}} divide-y divide-gray-200">
                <tr *ngFor="let save of list$ | async as saveList" class="hover:bg-blue-50">
                    <td *ngIf="!isCheatSavesListEnabled" class="relative w-12 pl-6">
                        <div *ngIf="isPlayerSelected(save.playerId)" class="absolute inset-y-0 left-0 w-0.5 bg-blue-400"></div>
                        <input type="checkbox" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-400 focus:ring-blue-400 focus:text-blue-400 sm:left-6" (change)="selectPlayer(save.playerId)" [checked]="isPlayerSelected(save.playerId)">
                    </td>
                    <td class="pl-6 text-sm text-gray-900">
                      <span *ngIf="!isCheatSavesListEnabled">{{save.id}}</span>
                      <a *ngIf="isCheatSavesListEnabled" href="/cheat-save-edit/{{save.id}}/cheat-save-list" class="text-blue-600 hover:text-blue-900">{{save.id}}</a>
                    </td>
                    <td *ngIf="isCheatSavesListEnabled" class="pl-2 text-sm text-gray-900">
                        {{save.saveName}}
                    </td>
                    <td *ngIf="!isCheatSavesListEnabled" class="pl-2 text-sm text-gray-900">
                      <a routerLink="/profile/{{save.playerId}}" routerLinkActive="active" class="truncate w-24 text-blue-500 hover:text-blue-700">{{save.playerId}}</a>
                    </td>
                    <td *ngIf="!isCheatSavesListEnabled" class="pl-2 text-sm text-gray-900 has-tooltip">
                        <span class='tooltip-profile'>{{save.deviceId}}</span>
                        <p class="truncate w-16">{{save.deviceId}}</p>
                    </td>
                    <td *ngIf="!isCheatSavesListEnabled" class="pl-2 text-sm text-gray-900 has-tooltip">
                        <span class='tooltip-profile'>{{save.gameSessionId}}</span>
                        <p class="truncate w-24">{{save.gameSessionId}}</p>
                    </td>
                    <td *ngIf="!isCheatSavesListEnabled" class="pl-2 text-sm text-gray-900">
                        {{save.receivedTime}}
                    </td>
                    <td *ngIf="!isCheatSavesListEnabled" class="pl-2 text-sm text-gray-900">
                        {{save.level}}
                    </td>
                    <td *ngIf="!isCheatSavesListEnabled" class="pl-2 text-sm text-gray-900">
                        {{save.coins}}
                    </td>
                    <td *ngIf="!isCheatSavesListEnabled" class="pl-2 text-sm text-gray-900">
                        {{save.ruby}}
                    </td>
                    <td *ngIf="!isCheatSavesListEnabled" class="pl-2 text-sm text-gray-900">
                        {{save.keys}}
                    </td>
                    <td *ngIf="!isCheatSavesListEnabled" class="pl-2 text-sm text-gray-900">
                        {{save.percent}}
                    </td>
                    <td *ngIf="!isCheatSavesListEnabled" class="pl-2 text-sm text-gray-900">
                        {{save.historyNumber}}
                    </td>
                    <td *ngIf="!isCheatSavesListEnabled" class="pl-2 pr-4 text-sm text-gray-900">
                        {{save.version}}
                    </td>
                    <td class="pl-6 pr-4 text-sm text-gray-900">
                        {{save.appVersion}}
                    </td>
                    <td *ngIf="isCheatSavesListEnabled" class="pl-2 pr-4 text-sm text-gray-900">
                        {{save.createdAt}}
                    </td>
                    <td *ngIf="isCheatSavesListEnabled" class="pl-2 pr-4 text-sm text-gray-900">
                        {{save.updatedAt}}
                    </td>
                    <td *ngIf="isCheatSavesListEnabled" class="pl-6 text-sm text-gray-900 flex flex-row">
                      <svg (click)="downloadCheatSaveDataAsMsgPackFile(save)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mr-2 w-5 h-5 text-blue-400 hover:text-blue-800 cursor-pointer">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m.75 12 3 3m0 0 3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                      </svg>
                      <svg *ngIf="appService.env === 'prod'" (click)="exportToEnv(save, 'test')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-blue-400 mr-2 hover:text-blue-800 cursor-pointer">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                      </svg>
                      <svg *ngIf="appService.env === 'test' || appService.env === 'dev'" (click)="exportToEnv(save, 'prod')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-red-400 mr-2 hover:text-red-800 cursor-pointer">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                      </svg>
                      <button (click)="deleteCheatSave(save.id)">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-red-400 mr-2 hover:text-red-800 cursor-pointer">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                        </svg>
                      </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<ng-template #orderDirectionDesc let-columnName="columnName">
  <div class="group inline-flex">
    <span class="relative top-0.5">{{columnName}}</span>
    <span class="ml-2 flex-none rounded bg-green-200 text-green-800" x-description="Active: &quot;bg-gray-200 text-gray-900 group-hover:bg-gray-300&quot;, Not Active: &quot;invisible text-gray-400 group-hover:visible group-focus:visible&quot;">
      <svg class="h-5 w-5" x-description="Heroicon name: mini/chevron-down" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd"></path>
      </svg>
    </span>
  </div>
</ng-template>

<ng-template #orderDirectionAsc let-columnName="columnName">
  <div class="group inline-flex">
    <span class="relative top-0.5">{{columnName}}</span>
    <span class="ml-2 flex-none rounded bg-green-200 text-green-800" x-description="Active: &quot;bg-gray-200 text-gray-900 group-hover:bg-gray-300&quot;, Not Active: &quot;invisible text-gray-400 group-hover:visible group-focus:visible&quot;">
      <svg class="h-5 w-5" x-description="Heroicon name: mini/chevron-down" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" transform="rotate(180)">
        <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd"></path>
      </svg>
    </span>
  </div>
</ng-template>
