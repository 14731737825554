import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {Observable} from "rxjs";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {SaveListService} from "./save-list.service";
import {Save} from "./save";
import {DeployListModalComponent} from "../deploy-list-modal/deploy-list-modal.component";
import {DeployListModalService} from "../deploy-list-modal/deploy-list-modal.service";
import {csvToArray} from "../../functions/csv-to-array";
import {ActivatedRoute, RouterLink, RouterLinkActive} from "@angular/router";
import {AppService} from "../../app.service";
import {CheatSaveListModalService} from "../cheat-save-list-modal/cheat-save-list-modal.service";
import {CheatSaveListModalComponent} from "../cheat-save-list-modal/cheat-save-list-modal.component";
import {PageTypeEnum} from "../../common/page";
import {LoaderService} from "../../navigation/loader/loader.service";
import {CheatSave} from "../cheat-save-list-modal/cheat-save";
import {CheatSaveService} from "../cheat-save/cheat-save.service";
import {AsyncPipe, NgForOf, NgIf, NgTemplateOutlet} from '@angular/common';
import {SelectRowsDirective} from '../../directives/select-rows.directive';
// import {QueryBuilderComponent} from '../query-builder/query-builder.component';
import {FileUploadComponent} from '../../components/file-upload/file-upload.component';
import {InternalNotificationService} from '../../functions/notification';

@Component({
  selector: 'app-save-list',
  templateUrl: './save-list.component.html',
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    SelectRowsDirective,
    NgTemplateOutlet,
    NgForOf,
    AsyncPipe,
    ReactiveFormsModule,
    // QueryBuilderComponent,
    FileUploadComponent,
    RouterLinkActive,
    // QueryBuilderComponent
  ],
  styleUrls: ['./save-list.component.scss']
})
export class SaveListComponent implements OnInit {

  list$: Observable<Save[]>;
  isAdvancedSearchEnabled = false;
  isCheatSavesListEnabled = false;

  searchSaveByPlayerIdForm = new FormGroup({
    playerId: new FormControl(null, [Validators.minLength(3)]),
  });

  searchCheatSaveByNameForm = new FormGroup({
    cheatSaveName: new FormControl(null, [Validators.minLength(1)]),
  });

  constructor(
    public appService: AppService,
    private route: ActivatedRoute,
    public saveListService: SaveListService,
    // public queryBuilderService: QueryBuilderService,
    public loaderService: LoaderService,
    private viewContainerRef: ViewContainerRef,
    private deployListModalService: DeployListModalService,
    private cheatSaveService: CheatSaveService,
    private cheatSaveListModalService: CheatSaveListModalService,
    private internalNotificationService: InternalNotificationService,
  ) {
    this.list$ = this.saveListService.list$;
    this.searchSavesByPlayerIdsCsvFileForm.controls.playerIdsCsvFile.addValidators(this.requiredFileType('csv'));
    this.searchSavesByPlayerIdsCsvFileForm.controls.playerIdsCsvFile.addAsyncValidators(this.csvContainsPlayerIdColumn);
  }

  searchSavesByPlayerIdsCsvFileForm = new FormGroup({
    playerIdsCsvFile: new FormControl(null)
  });

  isValidOrNullPlayerIdsCsvFile(): boolean|null {
    if (this.searchSavesByPlayerIdsCsvFileForm.controls.playerIdsCsvFile.value === null) {
      return null;
    }

    return this.searchSavesByPlayerIdsCsvFileForm.controls.playerIdsCsvFile.valid;
  }

  ngOnInit(): void {
    const type = this.route.snapshot.data['type'];
    this.appService.setPageType$(type)
    this.saveListService.list$.subscribe({
      next: (saves) => {
        this.saveListService.setAllPlayersNotSelected();
        this.saveListService.updatePlayerIdList(saves)
      },
    });

    if (type === PageTypeEnum.CHEAT_SAVE_LIST ||
        type === PageTypeEnum.CHEAT_SAVE_CREATE ||
        type === PageTypeEnum.CHEAT_SAVE_EDIT
    ) {
      this.enableCheatSavesList()
      this.saveListService.chooseSaveListDefaultPagination()
    } else {
      this.saveListService.chooseSaveListDefaultPagination()
      this.fetchSaveList([]);
    }
  }

  fetchSaveList(playerIds: string[], silencePreload: boolean = false) {
    this.saveListService.getList({
      'playerIds': playerIds,
      'query': null,
    }).subscribe(
      (data) => {
        if (data.body !== null) {
          this.saveListService.setList(data.body);
          this.saveListService.saveListTotalCount = Number(data.headers.get("x-total-count"));

          const rangeHeader = data.headers.get('Content-Range');
          if (rangeHeader !== null) {
            this.saveListService.parseRangeHeader(rangeHeader)
          }
        } else {
          this.saveListService.setList([]);
          this.saveListService.saveListTotalCount = 0;
          this.saveListService.updatePlayerIdList([])
        }
      }
    );
  }

  fetchCheatSaveList(searchCheatSaveName: string|null = null) {
    this.saveListService.getCheatSaveList(searchCheatSaveName).subscribe(
      (data) => {
        if (data.body !== null) {
          this.saveListService.setList(data.body);
          this.saveListService.saveListTotalCount = Number(data.headers.get("x-total-count"));

          const rangeHeader = data.headers.get('Content-Range');
          if (rangeHeader !== null) {
            this.saveListService.parseRangeHeader(rangeHeader)
          }
        } else {
          this.saveListService.setList([]);
          this.saveListService.saveListTotalCount = 0;
          this.saveListService.updatePlayerIdList([])
        }
      });
  }

  onChangePlayerIdForm() {
    if (this.searchSaveByPlayerIdForm.controls.playerId.value !== null
      && this.searchSaveByPlayerIdForm.controls.playerId.valid
    ) {
      if (String(this.searchSaveByPlayerIdForm.controls.playerId.value).trim() === '') {
        this.fetchSaveList([]);
      } else {
        this.saveListService.resetSelectedPageAndPagePlayers()
        this.saveListService.resetPagination(true)
        this.fetchSaveList(this.extractPlayerIds());
      }
      this.searchSavesByPlayerIdsCsvFileForm.reset();
    }
  }

  onChangeSaveNameForm() {
    if (this.searchCheatSaveByNameForm.controls.cheatSaveName.value !== null
      && this.searchCheatSaveByNameForm.controls.cheatSaveName.valid
    ) {
      const searchCheatSaveName = String(this.searchCheatSaveByNameForm.controls.cheatSaveName.value).trim()

      if (searchCheatSaveName === '') {
        this.saveListService.resetSelectedPageAndPagePlayers()
        this.saveListService.resetPagination(true)
        this.fetchCheatSaveList();
      } else {
        this.saveListService.resetSelectedPageAndPagePlayers()
        this.saveListService.resetPagination(true)
        this.fetchCheatSaveList(searchCheatSaveName);
      }
      this.searchSaveByPlayerIdForm.reset();
      this.searchSavesByPlayerIdsCsvFileForm.reset();
    }
  }

  private extractPlayerIds(): string[] {
    const regex = /[a-f\d]{8}-([a-f\d]{4}-){3}[a-f\d]{12}/gi;
    const trimmedInputText =  String(this.searchSaveByPlayerIdForm.controls.playerId.value).trim();
    const playerIds = trimmedInputText.match(regex) || [];

    return playerIds.length === 0 ? [trimmedInputText] : playerIds
  }

  onChangePlayerIdsCsvFileForm() {
    const sub = this.searchSavesByPlayerIdsCsvFileForm.statusChanges.subscribe(status => {
      if (this.searchSavesByPlayerIdsCsvFileForm.controls.playerIdsCsvFile.value !== null
        && this.searchSavesByPlayerIdsCsvFileForm.controls.playerIdsCsvFile.valid
      ) {
        const csvFile = this.searchSavesByPlayerIdsCsvFileForm.controls.playerIdsCsvFile.value as File;
        csvFile
          .text()
          .then((csvData) => {
            const parsedData = csvToArray(csvData);

            Object.keys(parsedData).forEach(key => {
              const playerId = (parsedData as any)[key]['playerId'].trim();

              if (playerId !== '') {
                this.saveListService.csvPlayerIds.push(playerId);
              }
            });

            console.log("CSV PlayerIds Count: ", this.saveListService.csvPlayerIds.length);
            this.saveListService.resetSelectedPageAndPagePlayers()
            this.saveListService.resetPagination()
            this.fetchSaveList(this.saveListService.csvPlayerIds);
            this.searchSaveByPlayerIdForm.reset();
            this.internalNotificationService.showSuccess('Успіх', 'Файл завантажено');
            sub.unsubscribe();
          });
      } else {
        sub.unsubscribe();
      }
    });
  }

  requiredFileType(type: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const file = control.value;

      if (file) {
        const extension = file.name.split('.')[1].toLowerCase();

        if (type.toLowerCase() === extension.toLowerCase()) {
          return null;
        } else {
          this.internalNotificationService.showError('Помилка!', 'Файл має бути формату .csv');
        }
      }

      return {invalidFileType: {value: control.value}};
    };
  }

  csvContainsPlayerIdColumn = async (control: AbstractControl): Promise<ValidationErrors | null> => {
    const csvFile = control.value as File;
    const csvData = await csvFile.text();

    try {
      const parsedData = csvToArray(csvData);

      Object.keys(parsedData).forEach(key => {
        const playerId = (parsedData as any)[key]['playerId'].trim();

        if (playerId !== '') {
        }
      });
    } catch (e) {
      this.internalNotificationService.showError('Помилка!', "Файл повинен мати колонку 'playerId' та відповідні рядки з Id гравців");
      return {requiredColumnNotFound: {value: control.value}};
    }
    return null;
  };

  enableAdvancedSearch(): boolean {
    this.isAdvancedSearchEnabled = !this.isAdvancedSearchEnabled;
    this.searchSaveByPlayerIdForm.reset();
    this.searchSavesByPlayerIdsCsvFileForm.reset();
    // this.queryBuilderService.setQueryBuilderQuery(null);
    // this.queryBuilderService.resetQueryBuilderRules();
    // this.saveListService.resetSelectedPageAndPagePlayers()
    // this.saveListService.resetPagination()

    return this.isAdvancedSearchEnabled;
  }

  enableCheatSavesList(): boolean {
    this.isCheatSavesListEnabled = !this.isCheatSavesListEnabled;
    this.searchSaveByPlayerIdForm.reset();
    this.searchSavesByPlayerIdsCsvFileForm.reset();
    // this.queryBuilderService.setQueryBuilderQuery(null);
    // this.queryBuilderService.resetQueryBuilderRules();
    this.saveListService.resetSelectedPageAndPagePlayers()
    this.saveListService.resetPagination(true)

    if (this.isCheatSavesListEnabled) {
      this.saveListService.chooseCheatSaveListDefaultPagination()
      this.fetchCheatSaveList();
    } else {
      this.saveListService.chooseSaveListDefaultPagination()
      this.fetchSaveList([]);
    }

    // this.saveListService.resetSelectedPageAndPagePlayers()
    // this.saveListService.resetPagination()
    return this.isCheatSavesListEnabled
  }

  deploy() {
    // if (!this.queryBuilderService.isValidQuery()) {
    //   this.internalNotificationService.showError('Помилка!', 'Запит має не валідні значення');
    //   return
    // }

    const deployListModalComponent = this.viewContainerRef.createComponent(DeployListModalComponent);

    // Тимчасово без завантаження існуючих деплоїв
    //
    // console.log('--->', this.queryBuilderService.queryBuilderQuery, this.saveListService.players);
    // this.deployListModalService.getDeployListModal().subscribe(
    //   (data) => {
    //     if (data.status === 200 || data.status === 204) {
    //       this.deployListModalService.setDeploys(data.body ?? []);
    //
    //       const deployListModalComponent = this.viewContainerRef.createComponent(DeployListModalComponent);
    //       deployListModalComponent.instance.addDeployListModal(this.deployListModalService.deploys);
    //       deployListModalComponent.instance.addDeployListModal([]);
    //
    //     }
    //   }
    // );
  }

  movePlayerSaveToCheatSave() {
    const playerId = this.getSelectedPlayer()

    if (!playerId) {
      return
    }

    this.cheatSaveListModalService.setSelectedPlayerId(playerId);
    this.saveListService.getPlayerSaveListByDay(<string>this.cheatSaveListModalService.selectedPlayerId).subscribe(
      (data) => {
        if (data.status === 200 || data.status === 204) {
          if (data.body) {
            this.cheatSaveListModalService.playerSaveMetadataByDay = data.body;
            this.cheatSaveListModalService.changeModalHeightForPlayerSaves(this.cheatSaveListModalService.playerSaveMetadataByDay.db)
          }
        }
      }
    )

    this.cheatSaveListModalService.getCheatSaveListModal().subscribe(
      (data) => {
        if (data.status === 200 || data.status === 204) {
          this.cheatSaveListModalService.setCheatSaves(data.body ?? []);
          this.cheatSaveListModalService.setSelectedPlayerId(playerId);
          this.cheatSaveListModalService.total = Number(data.headers.get("x-total-count"));

          const rangeHeader = data.headers.get('Content-Range');
          if (rangeHeader !== null) {
            this.cheatSaveListModalService.parseRangeHeader(rangeHeader)
          }

          this.cheatSaveListModalService.isMovingCheatSaveToPlayerSave = false
          this.cheatSaveListModalService.isMovingPlayerSaveToCheatSave = true
          const cheatSaveListModalComponent = this.viewContainerRef.createComponent(CheatSaveListModalComponent);
        }
      }
    );
  }

  moveCheatSaveToPlayerSave() {
    const playerId = this.getSelectedPlayer()

    if (!playerId) {
      return
    }

    this.cheatSaveListModalService.getCheatSaveListModal().subscribe(
      (data) => {
        if (data.status === 200 || data.status === 204) {
          this.cheatSaveListModalService.setCheatSaves(data.body ?? []);
          this.cheatSaveListModalService.setSelectedPlayerId(playerId);
          this.cheatSaveListModalService.total = Number(data.headers.get("x-total-count"));

          const rangeHeader = data.headers.get('Content-Range');
          if (rangeHeader !== null) {
            this.cheatSaveListModalService.parseRangeHeader(rangeHeader)
          }

          this.cheatSaveListModalService.isMovingCheatSaveToPlayerSave = true
          this.cheatSaveListModalService.isMovingPlayerSaveToCheatSave = false
          const cheatSaveListModalComponent = this.viewContainerRef.createComponent(CheatSaveListModalComponent);
        }
      }
    );
  }

  canBeDeployed(): boolean {
    return this.getSelectedPlayersCount() > 0 && !this.isCheatSavesListEnabled;
  }

  canMovePlayerSaveToCheatSave(): boolean {
    return this.getSelectedPlayersCount() === 1;
  }

  selectAllPlayers() {
    if (this.isFirstPageSelected()) {
      this.saveListService.setAllPlayersSelected();

    } else {
      if (!this.saveListService.selectedPages.includes(this.saveListService.currentPage)) {
        this.saveListService.selectedPages.push(this.saveListService.currentPage)
      } else {
        this.saveListService.selectedPages = this.saveListService.selectedPages.filter(id => id != this.saveListService.currentPage)
      }
    }

    if (!this.saveListService.selectedPagePlayers.has(this.saveListService.currentPage)) {
      this.saveListService.selectedPagePlayers.set(this.saveListService.currentPage, this.saveListService.listedPlayerIds)
    } else {
      let players = this.saveListService.selectedPagePlayers.get(this.saveListService.currentPage)

      if (players?.length === this.saveListService.listedPlayerIds.length) {
        this.saveListService.selectedPagePlayers.delete(this.saveListService.currentPage)
      } else {
        this.saveListService.selectedPagePlayers.set(this.saveListService.currentPage, this.saveListService.listedPlayerIds)
      }
    }

    // if (this.searchSavesByPlayerIdsCsvFileForm.controls.playerIdsCsvFile.value !== null
    //   && this.searchSavesByPlayerIdsCsvFileForm.controls.playerIdsCsvFile.valid
    // ) {
    //   this.saveListService.setPlayers(this.saveListService.isAllPlayersSelected ? this.listedPlayerIds : []);
    //   return;
    // }
    //
    // if (this.searchSaveByPlayerIdForm.controls.playerId.value !== null
    //   && this.searchSaveByPlayerIdForm.controls.playerId.valid
    // ) {
    //   this.saveListService.setPlayers(this.saveListService.isAllPlayersSelected ? this.listedPlayerIds : []);
    //   return;
    // }
  }

  isFirstPageSelected(): boolean {
    return this.saveListService.currentPage === 1
  }

  getSelectedPlayersCount(): number {
    let totalCount = 0;

    this.saveListService.selectedPagePlayers.forEach((players) => {
      totalCount += players.length;
    });

    return totalCount;
  }

  getSelectedPlayer(): string | null {
    let playerId: string | null = null;

    this.saveListService.selectedPagePlayers.forEach((players) => {
      if (players.length > 0 && playerId === null) {
        playerId = players[0]
      }
    });

    return playerId;
  }

  selectPlayer(playerId: string): void {
    this.saveListService.setAllPlayersNotSelected();

    if (this.saveListService.selectedPages.includes(this.saveListService.currentPage)) {
      this.saveListService.selectedPages = this.saveListService.selectedPages.filter(id => id != this.saveListService.currentPage)
    }

    if (this.saveListService.selectedPagePlayers.has(this.saveListService.currentPage)) {
      let players = this.saveListService.selectedPagePlayers.get(this.saveListService.currentPage)

      if (players?.includes(playerId)) {
        players = players.filter(id => id != playerId);
      } else {
        players?.push(playerId);
      }

      if (players !== undefined) {
        this.saveListService.selectedPagePlayers.set(this.saveListService.currentPage, players)
      }

      if (players?.length === this.saveListService.listedPlayerIds.length && !this.isFirstPageSelected()) {
        this.saveListService.selectedPages.push(this.saveListService.currentPage)
      }
    } else {
      this.saveListService.selectedPagePlayers.set(this.saveListService.currentPage, [playerId])
    }
  }

  isAllPlayersSelected(): boolean {
    if (this.getSelectedPlayersCount() === this.saveListService.saveListTotalCount) {
      return true
    }

    return this.saveListService.isAllPlayersSelected
  }

  isPlayerSelected(playerId: string) {
    if (this.saveListService.isAllPlayersSelected) {
      return true;
    }

    if (this.saveListService.selectedPagePlayers.has(this.saveListService.currentPage)) {
      const players = this.saveListService.selectedPagePlayers.get(this.saveListService.currentPage)
      return players?.includes(playerId);
    }

    return false;
  }

  changeOrderBy(columnName: string) {
    this.saveListService.changeOrderBy(columnName);

    if (this.isCheatSavesListEnabled) {
      this.fetchCheatSaveList()
    } else {
      this.fetchSaveList([])
    }
  }

  changePage(page: number) {
    this.saveListService.changePage(page);

    if (this.isCheatSavesListEnabled) {
      this.fetchCheatSaveList()
    } else {
      this.fetchSaveList(this.saveListService.csvPlayerIds)
    }
  }

  deleteCheatSave(cheatSaveId: number) {
    if (!confirm('Ви впевнені що хочете видалити чит сейв #' + cheatSaveId + '?')) {
      return;
    }

    this.saveListService.deleteCheatSave(cheatSaveId).subscribe({
      next: (data) => {
        if (data.status === 200) {
          this.internalNotificationService.showSuccess('Успіх', 'Чит сейв видалено');
          this.fetchCheatSaveList()
        }
      },
      error: (error) => {
        this.internalNotificationService.handleError(error, "Невдалося видалити ремоут конфіг");
      }
    })
  }

  exportToEnv(cheatSave: CheatSave | Save, env: string) {
    if (!confirm('Ви впевнені що хочете експортувати чит сейв #' + cheatSave.id + ' в ' + env + ' оточення')) {
      return;
    }

    this.saveListService.exportCheatSaveToEnv(cheatSave.id, env).subscribe({
      next: (data) => {
        if (data.status === 201) {
          this.internalNotificationService.showSuccess('Успіх', 'Чит сейв експортовано');
        }
      },
      error: (error) => {
        this.internalNotificationService.handleError(error, "Невдалося експортувати чит сейв");
      }
    })
  }

  downloadCheatSaveDataAsMsgPackFile(save: Save) {
    this.cheatSaveService.getCheatSave(save.id).subscribe({
      next: (data) => {
        if (data.body !== null) {
          const fileName = save.saveName + ' | ' + save.appVersion + ' | ' + save.createdAt
          const saveData = this.base64ToUint8Array(data.body.saveData) // base64 decode
          this.downloadCheatSaveAsMsgPackFile(fileName, saveData);
        }
      },
      error: (error) => {
        this.internalNotificationService.handleError(error, "Невдалося отримати чит сейв");
      }
    })
  }

  base64ToUint8Array(base64: string) {
    const binaryString = atob(base64); // Base64 decode
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  };

  downloadCheatSaveAsMsgPackFile(name: string, content: Uint8Array) {
    const blob = new Blob([content], { type: 'application/x-msgpack' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = name + '.msgpack';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
}
