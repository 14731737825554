import { Component, OnInit } from '@angular/core';
import {LoaderService} from "./loader.service";
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  standalone: true,
  imports: [
    NgIf
  ],
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  constructor(public loaderService: LoaderService) { }

  ngOnInit(): void {
  }
}
