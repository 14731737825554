import {ApplicationConfig, importProvidersFrom, provideZoneChangeDetection} from '@angular/core';
import {provideRouter} from '@angular/router';

import {routes} from './app.routes';
import {provideClientHydration} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {NotificationsService, SimpleNotificationsModule} from 'angular2-notifications';
import {HttpHeadersInterceptor} from './security/http-headers.interceptor';
import {TokenInterceptor} from './security/token.interceptor';
import {UnauthorizedInterceptor} from './security/unauthorized.interceptor';
import {LoaderInterceptor} from './security/loader.interceptor';
import {provideAnimations, provideNoopAnimations} from '@angular/platform-browser/animations';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({eventCoalescing: true}),
    provideRouter(routes),
    provideHttpClient(
      withInterceptorsFromDi(),
    ),
    provideClientHydration(),
    NotificationsService,
    provideAnimations(),
    provideNoopAnimations(),
    importProvidersFrom(SimpleNotificationsModule.forRoot()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpHeadersInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
  ]
};
