<!--
  Mobile menu

  Off-canvas menu for mobile, show/hide based on off-canvas menu state.
-->
<div *ngIf="isUserAuthenticated && isShowingMobileMenu" class="fixed inset-0 flex z-10 lg:hidden" role="dialog" aria-modal="true">
    <!--
      Off-canvas menu overlay, show/hide based on off-canvas menu state.

      Entering: "transition-opacity ease-linear duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "transition-opacity ease-linear duration-300"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <div class="fixed inset-0 bg-black bg-opacity-25" aria-hidden="true"></div>

    <!--
      Off-canvas menu, show/hide based on off-canvas menu state.

      Entering: "transition ease-in-out duration-300 transform"
        From: "-translate-x-full"
        To: "translate-x-0"
      Leaving: "transition ease-in-out duration-300 transform"
        From: "translate-x-0"
        To: "-translate-x-full"
    -->
    <div class="relative max-w-xs w-full bg-white shadow-xl pb-12 flex flex-col overflow-y-auto">
        <div class="p-2.5 flex">
            <button (click)="(isShowingMobileMenu=!isShowingMobileMenu)" type="button" class="-m-2 p-2 rounded-md inline-flex items-center justify-center text-gray-400">
                <span class="sr-only">Close menu</span>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
            </button>
        </div>

        <!-- Links -->
        <div>
            <div class="border-b border-t border-gray-200">
                <div class="-mb-px flex space-x-8" aria-orientation="horizontal" role="tablist">
                    <button (click)="setCurrentActiveMobileMenu('users')" id="tabs-1-tab-1" class="{{isShowingMobileUsersMenu() ? 'text-indigo-600 border-indigo-600' : 'text-gray-900 border-transparent'}} flex-1 whitespace-nowrap h-10 border-b text-base font-medium" aria-controls="tabs-1-panel-1" role="tab" type="button">
                        Користувачі
                    </button>

                    <button (click)="setCurrentActiveMobileMenu('players')" id="tabs-1-tab-2" class="{{isShowingMobilePlayersMenu() ? 'text-indigo-600 border-indigo-600' : 'text-gray-900 border-transparent'}} flex-1 whitespace-nowrap h-10 border-b text-base font-medium" aria-controls="tabs-1-panel-2" role="tab" type="button">
                        Гравці
                    </button>
                </div>
            </div>

            <!-- 'Users' tab panel, show/hide based on tab state. -->
            <div *ngIf="isShowingMobileUsersMenu()" id="tabs-1-panel-1" class="px-4 pt-10 pb-6 space-y-12" aria-labelledby="tabs-1-tab-1" role="tabpanel" tabindex="0">
                <div class="grid grid-cols-1 items-start gap-y-10 gap-x-6">
                    <div class="grid grid-cols-1 gap-y-10 gap-x-6">
                        <div>
                            <p id="mobile-featured-heading-0" class="font-medium text-gray-900">
                                Користувачі
                            </p>
                            <ul role="list" aria-labelledby="mobile-featured-heading-0" class="mt-6 space-y-6">
                                <li class="flex">
                                    <a routerLink="/user-list" routerLinkActive="active" class="text-gray-500 hover:text-indigo-600">
                                        Список користувачів
                                    </a>
                                </li>

                                <li class="flex">
                                    <a href="#" class="text-gray-500">
                                        -
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <p id="mobile-categories-heading" class="font-medium text-gray-900">
                                Додаткове меню
                            </p>
                            <ul role="list" aria-labelledby="mobile-categories-heading" class="mt-6 space-y-6">
                                <li class="flex">
                                    <a href="#" class="text-gray-500">
                                        -
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="grid grid-cols-1 gap-y-10 gap-x-6">
                    </div>
                </div>
            </div>

            <!-- 'Players' tab panel, show/hide based on tab state. -->
            <div *ngIf="isShowingMobilePlayersMenu()" id="tabs-1-panel-2" class="px-4 pt-10 pb-6 space-y-12" aria-labelledby="tabs-1-tab-2" role="tabpanel" tabindex="0">
                <div class="grid grid-cols-1 items-start gap-y-10 gap-x-6">
                    <div class="grid grid-cols-1 gap-y-10 gap-x-6">
                        <div>
                            <p id="mobile-featured-heading-1" class="font-medium text-gray-900">
                                Гравці
                            </p>
                            <ul role="list" aria-labelledby="mobile-featured-heading-1" class="mt-6 space-y-6">
                                <li class="flex">
                                    <a class="text-gray-500 hover:text-indigo-600"
                                       routerLink="/save-list" routerLinkActive="active">
                                        Список гравців
                                    </a>
                                </li>

                                <li class="flex">
                                    <a href="#" class="text-gray-500">
                                        -
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--
  Desktop menu
-->
<nav aria-label="Top" class="border-b border-gray-200">
    <!-- Secondary navigation -->
    <div class="bg-gray-50">
        <div class="max-w-full px-4 sm:px-6 lg:px-8">
            <div>
                <div class="h-10 flex items-center justify-between">
                    <!-- Logo (lg+) -->
                    <!--                    <disv class="hidden lg:flex lg:items-center">-->
                    <!--                        <a href="#">-->
                    <!--                            <span class="sr-only">Workflow</span>-->
                    <!--                            <img class="h-8 w-auto" src="https://tailwindui.com/img/logos/workflow-mark.svg?color=indigo&shade=600" alt="">-->
                    <!--                        </a>-->
                    <!--                    </div>-->

                    <div class="hidden h-full lg:flex" *ngIf="isUserAuthenticated">
                        <!-- Mega menus -->
                        <div class="text-xs text-gray-500">
                            <div class="h-full flex justify-center space-x-8">
<!--                                <div class="flex">-->
<!--                                    <div class="relative flex">-->
                                        <!-- Item active: "border-indigo-600 text-indigo-600", Item inactive: "border-transparent text-gray-700 hover:text-gray-800" -->
                                        <!--                                        <button type="button" class="border-transparent relative z-10 flex items-center transition-colors ease-out duration-200 border-b-2 -mb-px pt-px" aria-expanded="false">-->
                                        <!--                                            Users-->
                                        <!--                                        </button>-->
<!--                                        <a routerLink="/user-list" routerLinkActive="active" class="py-3 hover:text-blue-700 font-medium uppercase">Користувачі</a>-->
                                        <!--                                        <button class="hover:text-blue-700 font-medium uppercase" (mouseenter)="setCurrentActiveDesktopMenu('users')">Користувачі</button>-->
<!--                                    </div>-->
<!--                                    <div class="absolute top-full inset-x-0 text-gray-500 sm:text-sm z-10" *ngIf="isShowingDesktopUsersMenu()" (mouseleave)="setCurrentActiveDesktopMenu(null)">-->
<!--                                        &lt;!&ndash; Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow &ndash;&gt;-->
<!--                                        <div class="absolute inset-0 top-1/2 bg-white" aria-hidden="true"></div>-->

<!--                                        <div class="relative bg-white border-b border-gray-200">-->
<!--                                            <div class="max-w-7xl mx-auto px-8">-->
<!--                                                <div class="grid grid-cols-2 items-start gap-y-10 gap-x-8 pt-10 pb-12">-->
<!--                                                    <div class="grid grid-cols-2 gap-y-10 gap-x-8">-->
<!--                                                        <div>-->
<!--                                                            <p id="desktop-featured-heading-0" class="font-medium text-gray-900">-->
<!--                                                                Користувачі-->
<!--                                                            </p>-->
<!--                                                            <ul role="list" aria-labelledby="desktop-featured-heading-0" class="mt-6 space-y-6 sm:mt-4 sm:space-y-4">-->
<!--                                                                <li class="flex">-->
<!--                                                                    <a routerLink="/user-list" routerLinkActive="active" class="hover:text-gray-800">-->
<!--                                                                        Список користувачів-->
<!--                                                                    </a>-->
<!--                                                                </li>-->
<!--                                                            </ul>-->
<!--                                                        </div>-->
<!--                                                        <div>-->
<!--                                                            <p id="desktop-categories-heading" class="font-medium text-gray-900">-->
<!--                                                                Секції-->
<!--                                                            </p>-->
<!--                                                            <ul role="list" aria-labelledby="desktop-categories-heading" class="mt-6 space-y-6 sm:mt-4 sm:space-y-4">-->
<!--                                                                <li class="flex">-->
<!--                                                                    <p>-->
<!--&lt;!&ndash;                                                                    <a routerLink="/sections-values/mapping" routerLinkActive="active"  class="hover:text-gray-800">&ndash;&gt;-->
<!--                                                                        Зміна назв секцій-->
<!--                                                                    </p>-->
<!--                                                                </li>-->
<!--                                                            </ul>-->
<!--                                                        </div>-->
<!--                                                    </div>-->
<!--                                                    <div class="grid grid-cols-2 gap-y-10 gap-x-8">-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->

<!--                                <div class="flex">-->
<!--                                    <div class="relative flex">-->
<!--                                        &lt;!&ndash; Item active: "border-indigo-600 text-indigo-600", Item inactive: "border-transparent text-gray-700 hover:text-gray-800" &ndash;&gt;-->
<!--                                        &lt;!&ndash;                                        <button type="button" class="border-transparent relative z-10 flex items-center transition-colors ease-out duration-200 border-b-2 -mb-px pt-px" aria-expanded="false">&ndash;&gt;-->
<!--                                        &lt;!&ndash;                                            Players&ndash;&gt;-->
<!--                                        &lt;!&ndash;                                        </button>&ndash;&gt;-->
<!--                                        <button class="hover:text-blue-700 font-medium uppercase" (mouseenter)="setCurrentActiveDesktopMenu('players')">Гравці</button>-->
<!--                                    </div>-->

<!--                                    &lt;!&ndash;-->
<!--                                      'Men' mega menu, show/hide based on flyout menu state.-->

<!--                                      Entering: "transition ease-out duration-200"-->
<!--                                        From: "opacity-0"-->
<!--                                        To: "opacity-100"-->
<!--                                      Leaving: "transition ease-in duration-150"-->
<!--                                        From: "opacity-100"-->
<!--                                        To: "opacity-0"-->
<!--                                    &ndash;&gt;-->
<!--                                    <div class="absolute top-full inset-x-0 text-gray-500 sm:text-sm z-10" *ngIf="isShowingDesktopPlayersMenu()" (mouseleave)="setCurrentActiveDesktopMenu(null)">-->
<!--                                        &lt;!&ndash; Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow &ndash;&gt;-->
<!--                                        <div class="absolute inset-0 top-1/2 bg-white" aria-hidden="true"></div>-->

<!--                                        <div class="relative bg-white border-b border-gray-200">-->
<!--                                            <div class="max-w-7xl mx-auto px-8">-->
<!--                                                <div class="grid grid-cols-2 items-start gap-y-10 gap-x-8 pt-10 pb-12">-->
<!--                                                    <div class="grid grid-cols-2 gap-y-10 gap-x-8">-->
<!--                                                        <div>-->
<!--                                                            <p id="desktop-featured-heading-1" class="font-medium text-gray-900">-->
<!--                                                                Гравці-->
<!--                                                            </p>-->
<!--                                                            <ul role="list" aria-labelledby="desktop-featured-heading-1" class="mt-6 space-y-6 sm:mt-4 sm:space-y-4">-->
<!--                                                                <li class="flex">-->
<!--                                                                    <a routerLink="/save-list" routerLinkActive="active" class="hover:text-gray-800">-->
<!--                                                                        Список гравців-->
<!--                                                                    </a>-->
<!--                                                                </li>-->
<!--                                                            </ul>-->
<!--                                                        </div>-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->

                                <a routerLink="/save-list" routerLinkActive="active" class="py-3 hover:text-blue-700 font-medium uppercase" (click)="hideLeftSideMenu()">Гравці</a>
<!--                                <button class="text-gray-400 font-medium uppercase">Групи</button>-->
<!--                                <button class="text-gray-400 font-medium uppercase">Офери</button>-->
                                <a routerLink="/deploy-list" routerLinkActive="active" class="py-3 hover:text-blue-700 font-medium uppercase" (click)="hideLeftSideMenu()">Деплої</a>
                            </div>
                        </div>
                    </div>

                    <!-- Mobile menu and search (lg-) -->
                    <div class="flex-1 flex items-center lg:hidden">
                        <!-- Mobile menu toggle, controls the 'mobileMenuOpen' state. -->
                        <button (click)="isShowingMobileMenu=!isShowingMobileMenu" type="button" class="-ml-2 p-2 rounded-md text-gray-400">
                            <span class="sr-only">Open menu</span>
                            <!-- Heroicon name: outline/menu -->
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
                            </svg>
                        </button>
                    </div>

                    <!-- Logo (lg-) -->
                    <!--                    <a href="#" class="lg:hidden">-->
                    <!--                        <span class="sr-only">Workflow</span>-->
                    <!--                        <img src="https://tailwindui.com/img/logos/workflow-mark.svg?color=indigo&shade=600" alt="" class="h-8 w-auto">-->
                    <!--                    </a>-->

                    <div class="flex-1 flex items-center justify-end">
                        <div class="flex items-center lg:ml-8">
                            <div class="flex space-x-8" *ngIf="isUserAuthenticated">
                                <!--                                <div class="hidden lg:flex">-->
                                <!--                                    <a href="#" class="-m-2 p-2 text-gray-400 hover:text-gray-500">-->
                                <!--                                        <span class="sr-only">Search</span>-->
                                <!--                                        &lt;!&ndash; Heroicon name: outline/search &ndash;&gt;-->
                                <!--                                        <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">-->
                                <!--                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />-->
                                <!--                                        </svg>-->
                                <!--                                    </a>-->
                                <!--                                </div>-->

<!--                                <div class="flex">-->
<!--                                    <a href="#" class="-m-2 p-2 text-gray-400 hover:text-gray-500">-->
<!--                                        <span class="sr-only">Account</span>-->
<!--                                        &lt;!&ndash; Heroicon name: outline/user &ndash;&gt;-->
<!--                                        <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">-->
<!--                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />-->
<!--                                        </svg>-->
<!--                                    </a>-->
<!--                                </div>-->
                            </div>

<!--                            <span class="mx-4 h-6 w-px bg-gray-200 lg:mx-6" aria-hidden="true" *ngIf="isUserAuthenticated"></span>-->
                            <a *ngIf="!isUserAuthenticated" routerLink="/login" routerLinkActive="hidden" class="text-xs text-gray-500 hover:text-blue-700 font-medium uppercase">Вхід</a>
                            <app-user-logout *ngIf="isUserAuthenticated"></app-user-logout>
<!--                            <div class="flow-root">-->
<!--                                <div class="group -m-2 p-2 flex items-center">-->
<!--                                    &lt;!&ndash;                                    <div class="flex flex-col md:flex-row md:mx-6" *ngIf="!isUserAuthenticated">&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                        <a class="my-1 hover:text-indigo-500 md:mx-4 md:my-0"&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                           routerLink="/login" routerLinkActive="hidden">Login&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                        </a>&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                        &lt;!&ndash;                <a class="my-1 hover:text-indigo-500 md:mx-4 md:my-0"&ndash;&gt;&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                        &lt;!&ndash;                   routerLink="/registration" routerLinkActive="active">Registration&ndash;&gt;&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                        &lt;!&ndash;                </a>&ndash;&gt;&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                        &lt;!&ndash;                TODO Registration&ndash;&gt;&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                    </div>&ndash;&gt;-->

<!--                                </div>-->
<!--                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</nav>
