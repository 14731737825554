import {Component, Input, OnInit} from '@angular/core';
import {SkinListService} from "./skin-list.service";
import {Skin, SkinType, SoftCurrency} from "../skin/skin";
import {RenovationOperation, RenovationStatus} from "../renovation/renovation";
import {RenovationListService} from "../renovation-list/renovation-list.service";
import {HttpErrorResponse} from "@angular/common/http";
import {SkinService} from "../skin/skin.service";
import {NotificationsService} from "angular2-notifications";
import {RenovationService} from "../renovation/renovation.service";
import {RenovationOrderList} from "../renovation-list/renovation-list";
import {InternalNotificationService} from '../../../functions/notification';
import {NgForOf, NgIf, NgTemplateOutlet} from '@angular/common';

@Component({
  selector: 'app-skin-list',
  templateUrl: './skin-list.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgTemplateOutlet,
    NgForOf
  ],
  styleUrls: ['./skin-list.component.scss']
})
export class SkinListComponent implements OnInit {

  public readonly RenovationStatusPending = RenovationStatus.PENDING;
  public readonly RenovationStatusSaved = RenovationStatus.SAVED;
  public readonly RenovationStatusConfirmed = RenovationStatus.CONFIRMED;

  @Input() renovationId!: number;
  @Input() listType!: RenovationStatus;
  @Input() renovationOperation!: RenovationOperation;
  @Input() loadedSkins: Skin[] = [];

  isActiveSkinList: boolean = false;

  constructor(
    public renovationService: RenovationService,
    public renovationListService: RenovationListService,
    public skinListService: SkinListService,
    private skinService: SkinService,
    private internalNotificationService: InternalNotificationService
  ) {}

  ngOnInit(): void {}

  getIsActiveSkinList() {
    return this.isActiveSkinList
  }

  setIsActiveSkinList() {
    this.isActiveSkinList = !this.isActiveSkinList
  }

  getSkinTypeText(skinType: SkinType) {
    switch (Number(skinType)) {
      case SkinType.NONE:
        return 'None'
      case SkinType.BASE:
        return 'Базовий'
      case SkinType.COMMON:
        return 'Звичайний'
      case SkinType.EVENT_OR_TLE:
        return 'Евент чи TLE'
      case SkinType.AWARDS:
        return 'Нагорода'
      case SkinType.EVENT_AWARD:
        return 'Евент нагорода'
      default:
        return ''
    }
  }

  getCurrencyText(currency: SoftCurrency | null) {
    switch (Number(currency)) {
      case SoftCurrency.CURRENCY_RUBY:
        return 'ruby'
      case SoftCurrency.CURRENCY_COIN:
        return 'coin'
      default:
        return ''
    }
  }

  isRenovationOperationExport(): boolean {
    return this.renovationOperation === RenovationOperation.EXPORT
  }

  changeOrderBy(columnName: string) {
    this.skinListService.changeOrderBy(columnName);
    this.renovationListService.renovationOrderMap.set(this.renovationId.toString() + "-skins", {
      skinRenovationId: this.renovationId,
      element: 'skins',
      orderBy: this.skinListService.orderByColumn,
      orderType: this.skinListService.orderDirection,
    } as RenovationOrderList)
    this.renovationListService.fetchAndLoadSkinRenovations()
  }

  deleteSkin(skin: Skin) {
    if (!confirm("Ви впевнені що видалити скін: " + skin.name + '?')) {
      return;
    }

    this.skinService.deleteSkin(skin.id).subscribe({
      next: (data) => {
        if (data.status === 200) {
          this.internalNotificationService.showSuccess("Успіх", "Скін видалено")
          this.renovationListService.fetchAndLoadSkinRenovations()
        }
      },
      error: (error) => {
        this.internalNotificationService.handleError(error, "Невдалося видалити скін з очікування");
      }
    })
  }

  editSkin(skin: Skin) {
    this.renovationService.activateSkinForm();
    this.skinService.editableSkin = skin
  }
}
