import {Injectable} from '@angular/core';
import {Chunk} from "../chunk/chunk";
import {RenovationStatus} from "../renovation/renovation";
import {PaginationService} from "../../../common/pagination-service";

@Injectable({
  providedIn: 'root'
})
export class ChunkListService extends PaginationService {

  isActiveChunkList: boolean = false;
  isAllChunksSelected: boolean = false;

  selectedChunks: Chunk[] = []
  pendingChunks: Chunk[] = []
  savedChunks: Chunk[] = []
  confirmedChunks: Chunk[] = []
  notSelectedChunksWarning: string[] = [];

  constructor() {
    super(0, 30, 30, "createdAt"); // Pass default arguments to the parent class constructor
  }

  addPendingChunk(chunk: Chunk) {
    this.pendingChunks.push(chunk)
    this.isActiveChunkList = true;
  }

  getChunkById(chunkId: string): Chunk {
    return <Chunk>this.pendingChunks.find(chunk => chunk.id === chunkId)
      ?? this.savedChunks.find(chunk => chunk.id === chunkId)
      ?? this.confirmedChunks.find(chunk => chunk.id === chunkId)
  }

  selectChunk(chunk: Chunk): void {
    if (this.isChunkSelected(chunk)) {
      this.deselectChunk(chunk)
      return
    }

    if (this.notSelectedChunksWarning.includes(chunk.id)) {
      this.notSelectedChunksWarning = this.notSelectedChunksWarning.filter((id) => id !== chunk.id);
    }

    this.selectedChunks.push(chunk)
  }

  isChunkSelected(Chunk: Chunk): boolean {
    return this.selectedChunks.includes(Chunk)
  }

  deselectChunk(Chunk: Chunk): void {
    this.selectedChunks = this.selectedChunks.filter((chunk) => chunk !== Chunk);
  }

  selectAllChunks(): void {
    if (this.isAllChunksSelected) {
      this.deselectAllChunks()

      return
    }

    this.isAllChunksSelected = true
    this.pendingChunks.forEach((pr) => this.selectedChunks.push(pr))
  }

  deselectAllChunks(): void {
    this.isAllChunksSelected = false
    this.selectedChunks = []
  }

  updateRenovationStatusInSelectedChunks(status: RenovationStatus) {
    this.selectedChunks.forEach(chunk => {
      chunk.status = status;
    });
  }
}
