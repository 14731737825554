import { Component, OnInit } from '@angular/core';
import {UserListService} from "./user-list.service";
import {User} from "../user";
import {Observable, Subject} from "rxjs";
import {ActivatedRoute, Router, RouterLink, RouterLinkActive} from "@angular/router";
import {AppService} from "../../app.service";
import {AsyncPipe, NgForOf, NgIf} from '@angular/common';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgForOf,
    RouterLink,
    RouterLinkActive,
    AsyncPipe
  ],
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  list$: Observable<User[]>;

  constructor(
    private userListService: UserListService,
    private route: ActivatedRoute,
    public appService: AppService
  ) {
    this.list$ = this.userListService.list$;
  }

  ngOnInit(): void {
    const type = this.route.snapshot.data['type'];
    this.appService.setPageType$(type)

    this.userListService.getList().subscribe(
      (data) => {
        if (data.body !== null) {
          this.userListService.setList(data.body);
        }
        // const total = data.headers.get('content-range').split('/')[1];
        // this.service.setTotal(Number(total));
      }
    );
    // this.userList = this.userListService.getList();
  }
}
