import { Component, OnInit } from '@angular/core';
import {RenovationService} from "../renovation/renovation.service";
import {AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {trans} from "../../../../translations";
import {MapElementService} from "./map-element.service";
import {ChunkListService} from "../chunk-list/chunk-list.service";
import {MapElementListService} from "../map-element-list/map-element-list.service";
import {NotificationsService} from "angular2-notifications";
import {HttpErrorResponse} from "@angular/common/http";
import {allowedCharactersValidatorForUniqueId} from "../../../form-validators/validators";
import {RenovationListService} from "../renovation-list/renovation-list.service";
import {NgOptionComponent, NgSelectComponent} from '@ng-select/ng-select';
import {NgForOf, NgIf} from '@angular/common';
import {InternalNotificationService} from '../../../functions/notification';

@Component({
  selector: 'app-map-element',
  templateUrl: './map-element.component.html',
  standalone: true,
  imports: [
    NgSelectComponent,
    ReactiveFormsModule,
    NgOptionComponent,
    NgForOf,
    NgIf
  ],
  styleUrls: ['./map-element.component.scss']
})
export class MapElementComponent implements OnInit {

  public readonly trans = trans;

  constructor(
    private internalNotificationService: InternalNotificationService,
    private renovationListService: RenovationListService,
    public mapElementService: MapElementService,
    public mapElementListService: MapElementListService,
    public renovationService: RenovationService,
    public chunkListService: ChunkListService,
  ) {}

  ngOnInit(): void {
    if (this.mapElementService.editableMapElement) {
      if (!this.mapElementForm.dirty) {
        this.mapElementForm.reset({
          uniqueId: this.mapElementService.editableMapElement.uniqueId,
          chunkId: this.mapElementService.editableMapElement.chunk?.id,
          projection: this.mapElementService.editableMapElement.projection === null ? 'null' : this.mapElementService.editableMapElement.projection.toString(),
        });
        console.log("111", this.mapElementForm.value)
        this.mapElementForm.markAsDirty()
      }
    }
  }

  mapElementForm = new FormGroup({
    uniqueId:   new FormControl(null, [Validators.required, Validators.maxLength(128), allowedCharactersValidatorForUniqueId()]),
    chunkId:    new FormControl(null, [Validators.required]),
    projection: new FormControl(null, [Validators.required]), // default projection is null/free
  });

  save() {
    if (this.mapElementForm.valid) {
      const mapElement = this.mapElementService.createMapElement(
        this.mapElementForm.controls['uniqueId'].value,
        this.mapElementForm.controls['chunkId'].value,
        this.mapElementForm.controls['projection'].value,
      );

      this.mapElementService.savePendingMapElement(mapElement).subscribe({
        next: (data) => {
          if (data.status === 201) {
            this.mapElementListService.addPendingMapElement(mapElement)
            this.reset()
          }
        },
        error: (error) => {
          this.internalNotificationService.handleError(error, "Невдалося додати елемент мапи в очікування");
        }
      })
    }
  }

  update(): void {
    if (this.mapElementService.editableMapElement && this.mapElementForm.valid) {
      const modifiedMapElement = {
        ...this.mapElementService.editableMapElement,
        uniqueId: this.mapElementForm.controls.uniqueId.value,
        chunkId: this.mapElementForm.controls.chunkId.value,
        projection: this.mapElementForm.controls.projection.value,
      };

      this.mapElementService.updateMapElement(modifiedMapElement).subscribe({
        next: (data) => {
          if (data.status === 200) {
            this.internalNotificationService.showSuccess("Успіх", 'Елемент мапи змінено')
            this.renovationListService.fetchAndLoadSkinRenovations()
          }
        },
        error: (error) => {
          this.internalNotificationService.handleError(error, "Невдалося оновити елемент мапи");
        }
      })
    }
  }

  reset() {
    if (this.mapElementForm.dirty) {
      this.mapElementService.editableMapElement = null
      this.mapElementForm.reset({
        uniqueId: null,
        chunkId: null,
        projection: null,
      });
    }
  }

  getInputStyles(control: AbstractControl): string {
    if (control.dirty && control.invalid) {
      return 'focus:border-red-300 focus:ring-red-200 bg-red-50';
    }

    if (control.dirty && control.valid) {
      return 'focus:border-green-300 focus:ring-green-200 bg-green-50';
    }

    return 'focus:border-blue-300 focus:ring-blue-200 bg-white';
  }

  getDropdownStyles(control: AbstractControl): string {
    if (control.dirty && control.invalid) {
      return 'custom-select-container focus:border-red-300 focus:ring-red-200 bg-red-50';
    }

    if (control.dirty && control.valid) {
      return 'custom-select-container focus:border-green-300 focus:ring-green-200 bg-green-50';
    }

    return 'custom-select-container focus:border-blue-300 focus:ring-blue-200 bg-white';
  }
}
