import { Component, OnInit } from '@angular/core';
import {RenovationService} from "./renovation.service";
import {NgIf} from '@angular/common';
import {SetComponent} from '../set/set.component';
import {ChunkComponent} from '../chunk/chunk.component';
import {MapElementComponent} from '../map-element/map-element.component';
import {SkinComponent} from '../skin/skin.component';

@Component({
  selector: 'app-renovation',
  templateUrl: './renovation.component.html',
  standalone: true,
  imports: [
    NgIf,
    SetComponent,
    ChunkComponent,
    MapElementComponent,
    SkinComponent
  ],
  styleUrls: ['./renovation.component.scss']
})
export class RenovationComponent implements OnInit {

  constructor(
    public renovationService: RenovationService,
  ) {}

  ngOnInit(): void {}
}
