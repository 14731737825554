import {Component, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {
  ControlContainer,
  ControlValueAccessor,
  FormGroupDirective,
  NG_VALUE_ACCESSOR
} from "@angular/forms";

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadComponent,
      multi: true
    }
  ],
  standalone: true,
  viewProviders: [{provide: ControlContainer, useExisting: FormGroupDirective}]
})
export class FileUploadComponent implements ControlValueAccessor, OnInit, OnChanges {

  @Input() isValid: boolean|null = null;
  private _file: File | null = null;

  @HostListener('change', ['$event.target.files']) emitFiles( event: FileList ) {
    const file = event && event.item(0);
    this._file = file;
    this._onChange(file);
    // this._onTouched();
  }

  constructor(
    private host: ElementRef<HTMLInputElement>
  ) {}

  writeValue( value: File | null ) {
    // clear file input
    this.host.nativeElement.value = '';
    (<HTMLInputElement>document.getElementById('uploadFile')).value = "";
    this._file = null;
    this.isValid = null;
  }

  private _onChange: (value: File | null) => any = () => {};
  registerOnChange(fn: (value: File | null) => undefined): void {
    this._onChange = fn;
  }

  private _onTouched: () => any = () => {};
  registerOnTouched(fn: () => undefined): void {
    this._onTouched = fn;
  }

  get file(): File | null {
    return this._file;
  }

  getColor(): string {
    switch (this.isValid) {
      case null: return 'blue'
      case true: return 'green'
      case false: return 'red'
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isValid'].currentValue === null && changes['isValid'].previousValue !== null) {
      this.writeValue(null);
    }
  }

  ngOnInit() {
    // Initialize or fetch data if needed
  }
}
