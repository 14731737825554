import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {UserLoginService} from "./user-login.service";
import {RouterLink} from '@angular/router';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    RouterLink,
    NgIf
  ],
  providers: [UserLoginService]
})
export class UserLoginComponent implements OnInit {

  loginForm = new FormGroup({
    username: new FormControl(null, [Validators.required, Validators.pattern('[a-zA-Z0-9._-]*')]),
    password: new FormControl(null, [Validators.required, Validators.minLength(8), Validators.maxLength(72)]),
  });

  constructor(private userLoginService: UserLoginService) {}

  ngOnInit(): void {}

  onSubmit() {
    this.markAsTouchedInvalidControls(this.loginForm);

    if (this.loginForm.valid) {
      const formData = {...this.loginForm.value};
      this.userLoginService.login(formData.username, formData.password);
    }
  }

  protected markAsTouchedInvalidControls(form: FormGroup) {
    const controls = form.controls;
    for (const field in controls) {
      if (controls[field].invalid) {
        controls[field].markAsTouched();
      }
    }
  }
}
