import {
  Directive,
  Input,
  ElementRef,
  Inject,
  OnChanges
} from '@angular/core';

@Directive({
  standalone: true,
  selector: '[focusField]'
})
export class FocusDirective implements OnChanges {

  @Input('focusField') focusField: any;
  constructor(@Inject(ElementRef) private element: ElementRef) {}

  ngOnChanges(changes: any) {
    if (changes.focusField.currentValue) {
      this.element.nativeElement.focus();
    }
  }
}
