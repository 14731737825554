import {Component, OnInit} from '@angular/core';
import {AuthorizationService} from "../../security/authorization.service";
import {Menu} from "./menu";
import {environment} from "../../../environments/environment";
import {AppComponent} from "../../app.component";
import {AppService} from "../../app.service";
import {NgIf} from '@angular/common';
import {RouterLink, RouterLinkActive} from '@angular/router';
import {UserLogoutComponent} from '../../users/user-logout/user-logout.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    RouterLinkActive,
    UserLogoutComponent
  ],
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  currentActiveDesktopMenu: Menu|null = null;
  currentActiveMobileMenu: Menu = Menu.users;
  isShowingMobileMenu = true;

  constructor(
    private authorizationService: AuthorizationService,
    private appService: AppService
  ) {}

  get isUserAuthenticated(): boolean {
    return this.authorizationService.isAuthenticated;
  }

  hideLeftSideMenu() {
    this.appService.isFullLeftSideMenu = false;
  }

  setCurrentActiveDesktopMenu(currentActiveMenu: string|null) {
    this.currentActiveDesktopMenu = currentActiveMenu as Menu;
  }

  setCurrentActiveMobileMenu(currentActiveMenu: string) {
    this.currentActiveMobileMenu = currentActiveMenu as Menu;
  }

  isShowingDesktopUsersMenu(): boolean {
    return this.currentActiveDesktopMenu === Menu.users;
  }

  isShowingMobileUsersMenu(): boolean {
    return this.currentActiveMobileMenu === Menu.users;
  }

  isShowingDesktopPlayersMenu(): boolean {
    return this.currentActiveDesktopMenu === Menu.players;
  }

  isShowingMobilePlayersMenu(): boolean {
    return this.currentActiveMobileMenu === Menu.players;
  }

  ngOnInit(): void {
    this.authorizationService.isStillAuthenticated();
  }
}
