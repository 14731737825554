import { Component, OnInit } from '@angular/core';
import {Observable} from "rxjs";
import {ActivatedRoute, RouterLink, RouterLinkActive} from "@angular/router";
import {ProfileCommonService} from "./profile-common.service";
import {Player} from "../../player";
import {AsyncPipe, NgClass, NgForOf, NgIf} from '@angular/common';

@Component({
  selector: 'app-profile-common',
  templateUrl: './profile-common.component.html',
  standalone: true,
  imports: [
    RouterLink,
    NgForOf,
    RouterLinkActive,
    NgIf,
    AsyncPipe,
    NgClass
  ],
  styleUrls: ['./profile-common.component.scss']
})
export class ProfileCommonComponent implements OnInit {
  isShowingProfileActions = false;
  isShowingAllPlayerDevices = false;
  player$: Observable<Player>;

  constructor(private playerProfileService: ProfileCommonService, private route: ActivatedRoute) {
    this.player$ = this.playerProfileService.player$;
  }

  ngOnInit(): void {
    const playerId = this.route.snapshot.paramMap.get('id');

    if (playerId) {
      this.playerProfileService.getPlayer(playerId).subscribe(
        (data) => {
          if (data.body !== null) {
            this.playerProfileService.setPlayer(data.body);
          }
        }
      );
    } // TODO else redirect to 404
  }
}
