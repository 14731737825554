import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[appSelectRows]'
})
export class SelectRowsDirective {

  @Output() selectedRows = new EventEmitter<any[]>();

  private isMouseDown = false;
  private selectedRowIds: number[] = [];

  constructor(private el: ElementRef) { }

  @HostListener('mousedown') onMouseDown() {
    this.isMouseDown = true;
  }

  @HostListener('mouseup') onMouseUp() {
    this.isMouseDown = false;
    this.selectedRowIds = [];
  }

  @HostListener('mousemove', ['$event']) onMouseMove(event: MouseEvent) {
    if (this.isMouseDown) {
      const tableRows = this.el.nativeElement.querySelectorAll('tr');
      const targetRow = event.target as HTMLElement;
      const rowIndex = Array.from(tableRows).indexOf(targetRow);

      if (rowIndex !== -1 && !this.selectedRowIds.includes(rowIndex)) {
        this.selectedRowIds.push(rowIndex);
        const selectedRows = this.selectedRowIds.map(id => tableRows[id]);
        this.selectedRows.emit(selectedRows);
      }
    }
  }
}
