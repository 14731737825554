<!--<div class="flex flex-col h-screen">-->
<!--  <header class="relative">-->
<!--    <app-header></app-header>-->
<!--  </header>-->
<!--&lt;!&ndash;  <div #loader></div>&ndash;&gt;-->
<!--  <app-loader></app-loader>-->
<!--  <main class="container mx-auto sm:px-6 lg:px-8 mt-10 mb-auto pb-10">-->
<!--&lt;!&ndash;    <app-breadcrumb></app-breadcrumb>&ndash;&gt;-->
<!--    <simple-notifications></simple-notifications>-->
<!--    <router-outlet></router-outlet>-->
<!--  </main>-->
<!--  <footer>-->
<!--    <app-footer></app-footer>-->
<!--  </footer>-->
<!--</div>-->
<!--
  This example requires updating your template:

  ```
  <html class="h-full bg-white">
  <body class="h-full">
  ```
-->
<div>
  <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
  <div class="relative z-50 lg:hidden" role="dialog" aria-modal="true">
    <!--
      Off-canvas menu backdrop, show/hide based on off-canvas menu state.

      Entering: "transition-opacity ease-linear duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "transition-opacity ease-linear duration-300"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <div class="fixed inset-0 bg-gray-900/80"></div>

    <div class="fixed inset-0 flex">
      <!--
        Off-canvas menu, show/hide based on off-canvas menu state.

        Entering: "transition ease-in-out duration-300 transform"
          From: "-translate-x-full"
          To: "translate-x-0"
        Leaving: "transition ease-in-out duration-300 transform"
          From: "translate-x-0"
          To: "-translate-x-full"
      -->
      <div class="relative mr-16 flex w-full max-w-xs flex-1">
        <!--
          Close button, show/hide based on off-canvas menu state.

          Entering: "ease-in-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "ease-in-out duration-300"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
          <button type="button" class="-m-2.5 p-2.5">
            <span class="sr-only">Close sidebar</span>
            <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-100 px-6 pb-2 ring-1 ring-white/10">
          <div class="flex h-16 shrink-0 items-center">
            <img class="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500" alt="Your Company">
          </div>
          <nav class="flex flex-1 flex-col">
            <ul role="list" class="-mx-2 flex-1 space-y-1">
              <li>
                <!-- Current: "bg-gray-800 text-white", Default: "text-gray-400 hover:text-white hover:bg-gray-800" -->
                <a href="#" class="bg-gray-200 text-white group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold">
                  <svg class="h-6 w-6 shrink-0" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                  </svg>
                  Dashboard
                </a>
              </li>

              <li>
                <a href="#" class="text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold">
                  <svg class="h-6 w-6 shrink-0" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                  </svg>
                  Team
                </a>
              </li>

              <li>
                <a href="#" class="text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold">
                  <svg class="h-6 w-6 shrink-0" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z" />
                  </svg>
                  Projects
                </a>
              </li>

              <li>
                <a href="#" class="text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold">
                  <svg class="h-6 w-6 shrink-0" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                  </svg>
                  Calendar
                </a>
              </li>

              <li>
                <a href="#" class="text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold">
                  <svg class="h-6 w-6 shrink-0" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                  </svg>
                  Documents
                </a>
              </li>

              <li>
                <a href="#" class="text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold">
                  <svg class="h-6 w-6 shrink-0" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
                  </svg>
                  Reports
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <!-- Static sidebar for desktop -->
  <!--  <div class="hidden lg:fixed lg:inset-y-0 lg:left-0 lg:z-50 lg:block lg:w-20 lg:overflow-y-auto lg:bg-gray-50 lg:pb-4 border-r border-gray-200">-->
  <div class="lg:fixed px-4 lg:inset-y-0 lg:left-0 lg:z-50 lg:block {{isFullLeftSideMenu ? 'lg:w-80' : 'lg:w-20'}} lg:overflow-y-auto lg:bg-gray-50 lg:pb-4 border-r border-gray-200">
    <div *ngIf="pageType$ | async as pageType" class="flex h-10 mb-{{isEditPage(pageType) ? '2' : '10'}} shrink-0 items-center justify-center text-gray-400 text-2xl">
      <span class="text-{{envColor}}-500 text-xs font-medium uppercase">{{appService.env}}</span>
    </div>
    <nav *ngIf="pageType$ | async as pageType" class="mt-2.5">
      <ul *ngIf="authorizationService.isAuthenticated && !isCreatePage(pageType) && !isEditPage(pageType)" role="list" class="flex flex-col items-center space-y-1">
        <li class="has-tooltip">
          <span class='tooltip-profile'>Загальне</span>
          <a routerLink="/" (click)="selectOption('/')" routerLinkActive="active" class="{{isDashboardPage(pageType) ? 'bg-blue-100 text-blue-500' : 'text-gray-400 hover:text-white hover:bg-gray-200'}} group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 0 0 6 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0 1 18 16.5h-2.25m-7.5 0h7.5m-7.5 0-1 3m8.5-3 1 3m0 0 .5 1.5m-.5-1.5h-9.5m0 0-.5 1.5m.75-9 3-3 2.148 2.148A12.061 12.061 0 0 1 16.5 7.605" />
            </svg>
          </a>
        </li>
        <li class="has-tooltip">
          <span class='tooltip-profile'>Мій профіль</span>
          <a routerLink="/user/{{getUserId()}}/profile" (click)="selectOption('/user-edit')" routerLinkActive="active" class="{{isUserEditPage(pageType) ? 'bg-blue-100 text-blue-500' : 'text-gray-400 hover:text-white hover:bg-gray-200'}} group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
            </svg>
          </a>
        </li>
        <li class="has-tooltip">
          <span class='tooltip-profile'>Команда</span>
          <a routerLink="/user-list" (click)="selectOption('/user-list')" routerLinkActive="active" class="{{isUserListPage(pageType) ? 'bg-blue-100 text-blue-500' : 'text-gray-400 hover:text-white hover:bg-gray-200'}} group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold">
            <svg class="h-6 w-6 shrink-0" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
            </svg>
          </a>
        </li>
        <li class="has-tooltip">
          <span class='tooltip-profile'>Налаштування</span>
          <a routerLink="/user-settings" (click)="selectOption('/user-settings')" routerLinkActive="active" class="{{isUserSettingsPage(pageType) ? 'bg-blue-100 text-blue-500' : 'text-gray-400 hover:text-white hover:bg-gray-200'}} group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z" />
            </svg>
          </a>
        </li>
        <!--        <li class="has-tooltip pt-10">-->
        <!--          <span class='tooltip-profile'>Створити редім деплой</span>-->
        <!--          <a routerLink="/deploy-create/save-list" (click)="selectOption('/deploy-create/save-list')" routerLinkActive="active" class="{{isDeployCreatePage(pageType) ? 'bg-blue-100 text-blue-500' : 'text-gray-400 hover:text-white hover:bg-gray-200'}} group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold">-->
        <!--            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">-->
        <!--              <path stroke-linecap="round" stroke-linejoin="round" d="M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />-->
        <!--            </svg>-->
        <!--          </a>-->
        <!--        </li>-->
        <!--        <li class="has-tooltip">-->
        <!--          <span class='tooltip-profile'>Створити оффер</span>-->
        <!--          <a routerLink="/offer-create/save-list" (click)="selectOption('/offer-create/save-list')" routerLinkActive="active" class="{{isOfferCreatePage(pageType) ? 'bg-blue-100 text-blue-500' : 'text-gray-400 hover:text-white hover:bg-gray-200'}} group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold">-->
        <!--            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">-->
        <!--              <path stroke-linecap="round" stroke-linejoin="round" d="M9 14.25l6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185zM9.75 9h.008v.008H9.75V9zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm4.125 4.5h.008v.008h-.008V13.5zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />-->
        <!--            </svg>-->
        <!--          </a>-->
        <!--        </li>-->
        <li class="has-tooltip pt-10">
          <span class='tooltip-profile'>Додати чит сейв</span>
          <a routerLink="/cheat-save-create/cheat-save-list" (click)="selectOption('/cheat-save-create/cheat-save-list')" routerLinkActive="active" class="{{isCheatSaveCreatePage(pageType) ? 'bg-blue-100 text-blue-500' : 'text-gray-400 hover:text-white hover:bg-gray-200'}} group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
            </svg>
          </a>
        </li>
        <li class="has-tooltip">
          <span class='tooltip-profile'>Додати ремоут конфіг</span>
          <a routerLink="/remote-config-create/remote-config-list" (click)="selectOption('/remote-config-create/remote-config-list')" routerLinkActive="active" class="{{isRemoteConfigCreatePage(pageType) ? 'bg-blue-100 text-blue-500' : 'text-gray-400 hover:text-white hover:bg-gray-200'}} group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
          </a>
        </li>
        <li class="has-tooltip">
          <span class='tooltip-profile'>Додати скіни</span>
          <a routerLink="/renovation-create/renovation-list" (click)="selectOption('/renovation-create/renovation-list')" routerLinkActive="active" class="{{isRenovationCreatePage(pageType) ? 'bg-blue-100 text-blue-500' : 'text-gray-400 hover:text-white hover:bg-gray-200'}} group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
            </svg>
          </a>
        </li>
      </ul>

      <ul *ngIf="isCreatePage(pageType) || isEditPage(pageType)" role="list" class="flex flex-col items-center space-y-1">
        <li class="has-tooltip">
          <span class='tooltip-profile'>Відмінити</span>
          <a routerLink="/user/{{getUserId()}}/profile" (click)="selectOption('/user-edit')" routerLinkActive="active" class="bg-red-100 text-red-500 hover:bg-red-200 group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </a>
        </li>
      </ul>
    </nav>
    <div *ngIf="pageType$ | async as pageType">
      <!--      <app-deploy *ngIf="isDeployCreatePage(pageType)"></app-deploy>-->
      <app-renovation *ngIf="isRenovationCreatePage(pageType)"></app-renovation>
      <app-remote-config *ngIf="isRemoteConfigCreatePage(pageType)"></app-remote-config>
      <app-remote-config *ngIf="isRemoteConfigEditPage(pageType)"></app-remote-config>
      <app-cheat-save *ngIf="isCheatSaveCreatePage(pageType)"></app-cheat-save>
      <app-cheat-save *ngIf="isCheatSaveEditPage(pageType)"></app-cheat-save>
    </div>

    <!--    <div class="space-y-6 pb-16">-->
    <!--      <div>-->
    <!--        <div class="mt-4 flex items-start justify-between">-->
    <!--          <div>-->
    <!--            <h2 class="text-base font-semibold leading-6 text-gray-900"><span class="sr-only">Details for </span>IMG_4985.HEIC</h2>-->
    <!--            <p class="text-sm font-medium text-gray-500">3.9 MB</p>-->
    <!--          </div>-->
    <!--          <button type="button" class="ml-4 flex h-8 w-8 items-center justify-center rounded-full bg-white text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500">-->
    <!--            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">-->
    <!--              <path stroke-linecap="round" stroke-linejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"></path>-->
    <!--            </svg>-->
    <!--            <span class="sr-only">Favorite</span>-->
    <!--          </button>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div>-->
    <!--        <h3 class="font-medium text-gray-900">Information</h3>-->
    <!--        <dl class="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">-->
    <!--          <div class="flex justify-between py-3 text-sm font-medium">-->
    <!--            <dt class="text-gray-500">Uploaded by</dt>-->
    <!--            <dd class="text-gray-900">Marie Culver</dd>-->
    <!--          </div>-->
    <!--          <div class="flex justify-between py-3 text-sm font-medium">-->
    <!--            <dt class="text-gray-500">Created</dt>-->
    <!--            <dd class="text-gray-900">June 8, 2020</dd>-->
    <!--          </div>-->
    <!--          <div class="flex justify-between py-3 text-sm font-medium">-->
    <!--            <dt class="text-gray-500">Last modified</dt>-->
    <!--            <dd class="text-gray-900">June 8, 2020</dd>-->
    <!--          </div>-->
    <!--          <div class="flex justify-between py-3 text-sm font-medium">-->
    <!--            <dt class="text-gray-500">Dimensions</dt>-->
    <!--            <dd class="text-gray-900">4032 x 3024</dd>-->
    <!--          </div>-->
    <!--          <div class="flex justify-between py-3 text-sm font-medium">-->
    <!--            <dt class="text-gray-500">Resolution</dt>-->
    <!--            <dd class="text-gray-900">72 x 72</dd>-->
    <!--          </div>-->
    <!--        </dl>-->
    <!--      </div>-->
    <!--      <div>-->
    <!--        <h3 class="font-medium text-gray-900">Description</h3>-->
    <!--        <div class="mt-2 flex items-center justify-between">-->
    <!--          <p class="text-sm italic text-gray-500">Add a description to this image.</p>-->
    <!--          <button type="button" class="-mr-2 flex h-8 w-8 items-center justify-center rounded-full bg-white text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500">-->
    <!--            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">-->
    <!--              <path d="M2.695 14.763l-1.262 3.154a.5.5 0 00.65.65l3.155-1.262a4 4 0 001.343-.885L17.5 5.5a2.121 2.121 0 00-3-3L3.58 13.42a4 4 0 00-.885 1.343z"></path>-->
    <!--            </svg>-->
    <!--            <span class="sr-only">Add description</span>-->
    <!--          </button>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div>-->
    <!--        <h3 class="font-medium text-gray-900">Shared with</h3>-->
    <!--        <ul role="list" class="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">-->
    <!--          <li class="flex items-center justify-between py-3">-->
    <!--            <div class="flex items-center">-->
    <!--              <img src="https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=3&amp;w=1024&amp;h=1024&amp;q=80" alt="" class="h-8 w-8 rounded-full">-->
    <!--              <p class="ml-4 text-sm font-medium text-gray-900">Aimee Douglas</p>-->
    <!--            </div>-->
    <!--            <button type="button" class="ml-6 rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Remove<span class="sr-only"> Aimee Douglas</span></button>-->
    <!--          </li>-->
    <!--          <li class="flex items-center justify-between py-3">-->
    <!--            <div class="flex items-center">-->
    <!--              <img src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&amp;ixqx=oilqXxSqey&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=2&amp;w=256&amp;h=256&amp;q=80" alt="" class="h-8 w-8 rounded-full">-->
    <!--              <p class="ml-4 text-sm font-medium text-gray-900">Andrea McMillan</p>-->
    <!--            </div>-->
    <!--            <button type="button" class="ml-6 rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Remove<span class="sr-only"> Andrea McMillan</span></button>-->
    <!--          </li>-->
    <!--          <li class="flex items-center justify-between py-2">-->
    <!--            <button type="button" class="group -ml-1 flex items-center rounded-md bg-white p-1 focus:outline-none focus:ring-2 focus:ring-indigo-500">-->
    <!--              <span class="flex h-8 w-8 items-center justify-center rounded-full border-2 border-dashed border-gray-300 text-gray-400">-->
    <!--                <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">-->
    <!--                  <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z"></path>-->
    <!--                </svg>-->
    <!--              </span>-->
    <!--              <span class="ml-4 text-sm font-medium text-indigo-600 group-hover:text-indigo-500">Share</span>-->
    <!--            </button>-->
    <!--          </li>-->
    <!--        </ul>-->
    <!--      </div>-->
    <!--      <div class="flex">-->
    <!--        <button type="button" class="flex-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Download</button>-->
    <!--        <button type="button" class="ml-3 flex-1 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Delete</button>-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="sticky top-0 z-40 flex items-center gap-x-6 bg-gray-900 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
      <button type="button" class="-m-2.5 p-2.5 text-gray-400 lg:hidden">
        <span class="sr-only">Open sidebar</span>
        <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
        </svg>
      </button>
      <div class="flex-1 text-sm font-semibold leading-6 text-white">Dashboard</div>
      <a href="#">
        <span class="sr-only">Your profile</span>
        <img class="h-8 w-8 rounded-full bg-gray-200" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="">
      </a>
    </div>

    <aside style="width: 100%" class="fixed inset-y-0 {{isFullLeftSideMenu ? 'left-80' : 'left-20'}} hidden overflow-y-auto border-r border-gray-200 xl:block">
      <!-- Secondary column (hidden on smaller screens) -->
      <header class="relative">
        <app-header></app-header>
      </header>
      <!--  <div #loader></div>-->
      <app-loader></app-loader>
      <main style="{{isFullLeftSideMenu ? 'margin: 2.5rem 21.5rem 2.5rem 1.5rem;' : 'margin: 2.5rem 5.5rem 2.5rem 1.5rem;'}}">
        <!--    <app-breadcrumb></app-breadcrumb>-->
        <simple-notifications [options]="options"></simple-notifications>
        <router-outlet></router-outlet>
      </main>
    </aside>
  </div>
</div>
