import { Component, OnInit } from '@angular/core';
import {NotificationsService} from "angular2-notifications";
import {CheatSaveListModalService} from "./cheat-save-list-modal.service";
import {CheatSave} from "./cheat-save";
import {HttpErrorResponse} from "@angular/common/http";
import {SaveListService} from "../save-list/save-list.service";
import {SaveMetadata} from "../save-list/save";
import {DatePipe, NgForOf, NgIf} from '@angular/common';
import {InternalNotificationService} from '../../functions/notification';

@Component({
  selector: 'app-cheat-save-list-modal',
  templateUrl: './cheat-save-list-modal.component.html',
  standalone: true,
  imports: [
    NgIf,
    DatePipe,
    NgForOf
  ],
  styleUrls: ['./cheat-save-list-modal.component.scss']
})
export class CheatSaveListModalComponent implements OnInit {
  isShowingCheatSaveModal = true;
  selectedCheatSave: CheatSave|null = null;
  selectedPlayerSave: SaveMetadata|null = null

  constructor(
    private internalNotificationService: InternalNotificationService,
    public cheatSaveListModalService: CheatSaveListModalService,
  ) { }

  ngOnInit(): void {}

  choosePlayerSaveByDay(saveMetadata: SaveMetadata): any {
    if (this.selectedPlayerSave && this.selectedPlayerSave.id === saveMetadata.id) {
      this.selectedPlayerSave = null

      return
    }

    this.selectedPlayerSave = saveMetadata;
  }

  chooseSaveToExportIntoCheatSave(cheatSave: CheatSave): any {
    if (this.selectedCheatSave && this.selectedCheatSave.id === cheatSave.id) {
      this.selectedCheatSave = null

      return
    }

    this.selectedCheatSave = cheatSave;
  }

  closeModal() {
    this.isShowingCheatSaveModal = false;
  }

  showMoreCheatSaves() {
    if (this.cheatSaveListModalService.currentPage < this.cheatSaveListModalService.totalPage) {
      this.cheatSaveListModalService.changePage(this.cheatSaveListModalService.currentPage + 1)
    } else {
      return
    }

    this.cheatSaveListModalService.getCheatSaveListModal().subscribe(
      (data) => {
        if (data.status === 200 || data.status === 204) {
          if (this.cheatSaveListModalService.cheatSaves.length > 0) {
            this.cheatSaveListModalService.setCheatSaves(this.cheatSaveListModalService.cheatSaves.concat(data.body as CheatSave[]));
          } else {
            this.cheatSaveListModalService.setCheatSaves(data.body ?? []);
          }
          this.cheatSaveListModalService.total = Number(data.headers.get("x-total-count"));

          const rangeHeader = data.headers.get('Content-Range');
          if (rangeHeader !== null) {
            this.cheatSaveListModalService.parseRangeHeader(rangeHeader)
          }
        }
      }
    );
  }

  exportPlayerSaveToCheatSave() {
    if (this.cheatSaveListModalService.selectedPlayerId &&
      this.selectedCheatSave &&
      this.selectedPlayerSave &&
      this.cheatSaveListModalService.isMovingPlayerSaveToCheatSave
    ) {
      this.cheatSaveListModalService.exportPlayerSaveToCheatSave(this.selectedCheatSave, this.selectedPlayerSave).subscribe({
        next: (data) => {
          if (data.status === 200) {
            this.closeModal();
            this.internalNotificationService.showSuccess('Успіх', "Save data гравця: "+this.cheatSaveListModalService.selectedPlayerId+" перенесено в чит сейв id: " + this.selectedCheatSave?.id)
          }
        },
        error: (error) => {
          this.internalNotificationService.handleError(error, "Невдалося експортувати сейв гравця до чит сейву");
        }
      })
    }
  }

  importCheatSaveToPlayerSave() {
    if (this.cheatSaveListModalService.selectedPlayerId &&
      this.selectedCheatSave &&
      this.cheatSaveListModalService.isMovingCheatSaveToPlayerSave
    ) {
      this.cheatSaveListModalService.importCheatSaveToPlayerSave(this.selectedCheatSave).subscribe({
        next: (data) => {
          if (data.status === 200) {
            this.closeModal();
            this.internalNotificationService.showSuccess('Успіх', "Чит сейв: "+ this.selectedCheatSave?.id + " перенесено до гравця: " + this.cheatSaveListModalService.selectedPlayerId)
          }
        },
        error: (error) => {
          this.internalNotificationService.handleError(error, "Невдалося імпортувати чит сейв до гравця");
        }
      })
    }
  }
}
