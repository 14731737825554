import {Component, Input, OnInit} from '@angular/core';
import {Set} from "../set/set";
import {SetListService} from "./set-list.service";
import {RenovationStatus} from "../renovation/renovation";
import {HttpErrorResponse} from "@angular/common/http";
import {SetService} from "../set/set.service";
import {RenovationListService} from "../renovation-list/renovation-list.service";
import {NotificationsService} from "angular2-notifications";
import {RenovationService} from "../renovation/renovation.service";
import {RenovationOrderList} from "../renovation-list/renovation-list";
import {InternalNotificationService} from '../../../functions/notification';
import {NgForOf, NgIf, NgTemplateOutlet} from '@angular/common';

@Component({
  selector: 'app-set-list',
  templateUrl: './set-list.component.html',
  standalone: true,
  imports: [
    NgTemplateOutlet,
    NgIf,
    NgForOf
  ],
  styleUrls: ['./set-list.component.scss']
})
export class SetListComponent implements OnInit {

  public readonly RenovationStatusPending = RenovationStatus.PENDING;
  public readonly RenovationStatusSaved = RenovationStatus.SAVED;
  public readonly RenovationStatusConfirmed = RenovationStatus.CONFIRMED;

  @Input() renovationId!: number;
  @Input() listType!: RenovationStatus;
  @Input() loadedSets: Set[] = [];

  isActiveSetList: boolean = false;

  constructor(
    private internalNotificationService: InternalNotificationService,
    private renovationService: RenovationService,
    public renovationListService: RenovationListService,
    public setListService: SetListService,
    public setService: SetService,
  ) {}

  ngOnInit(): void {}

  getIsActiveSetList() {
    return this.isActiveSetList
  }

  setIsActiveSetList() {
    this.isActiveSetList = !this.isActiveSetList
  }

  changeOrderBy(columnName: string) {
    this.setListService.changeOrderBy(columnName);
    this.renovationListService.renovationOrderMap.set(this.renovationId.toString() + "-sets", {
      skinRenovationId: this.renovationId,
      element: 'sets',
      orderBy: this.setListService.orderByColumn,
      orderType: this.setListService.orderDirection,
    } as RenovationOrderList)
    this.renovationListService.fetchAndLoadSkinRenovations()
  }

  deleteSet(set: Set) {
    if (!confirm("Ви впевнені що видалити сет: " + set.name + ' та скіни що його використовують?')) {
      return;
    }

    this.setService.deleteSet(set.id).subscribe({
      next: (data) => {
        if (data.status === 201) {
          this.internalNotificationService.showSuccess('Успіх', 'Сет видалено');
          this.renovationListService.fetchAndLoadSkinRenovations()
        }
      },
      error: (error) => {
        this.internalNotificationService.handleError(error, "Невдалося видалити сет з очікування");
      }
    })
  }

  editSet(set: Set) {
    this.renovationService.activateSetForm();
    this.setService.editableSet = set
  }
}
