import {NotificationsService} from 'angular2-notifications';
import {HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable, Injector} from '@angular/core';

@Injectable({
  providedIn: 'root', // Makes this service available application-wide
})
export class InternalNotificationService {

  // private notificationsService = inject(NotificationsService);


  constructor(private notificationsService: NotificationsService) {}

  // constructor(private injector: Injector) {
  //   // this.getNotificationsService()
  // }

  // private getNotificationsService(): NotificationsService {
  //   if (!this.notificationsService) {
  //     this.notificationsService = this.injector.get(NotificationsService);
  //   }
  //   return this.notificationsService;
  // }

  showInfo(message: string, title: string = 'Інфо') {
    this.notificationsService.info(title, message, {
      timeOut: 1500,
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true
    });
  }

  showSuccess(message: string, title: string = 'Успіх') {
    console.log(this.notificationsService)
    this.notificationsService.success(title, message, {
      timeOut: 1500,
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true
    });
  }

  showWarn(message: string, title: string = 'Увага') {
    console.warn(title, message);
    this.notificationsService.warn(title, message, {
      timeOut: 5000,
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true
    });
  }

  showError(message: string, title: string = 'Помилка') {
    console.error(title, message);
    this.notificationsService.error(title, message, {
      timeOut: 5000,
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true
    });
  }

  handleError(error: any, defaultMessage: string = 'Внутрішня помилка') {
    if (error instanceof HttpErrorResponse && error.error?.detail) {
      this.showError(error.error.detail, 'Помилка!');
    } else {
      this.showError(defaultMessage, 'Помилка!');
    }
  }
}
